import styled from '@emotion/styled';

const StyledContainer = styled('div')`
  margin-top: 100px;
  padding: 0 7vw;

  .ant-steps-item-icon {
    visibility: hidden;
  }

  .ant-steps-navigation .ant-steps-item::after {
    visibility: hidden;
  }

  .ant-steps-navigation {
    padding-top: 0px;
    height: 5px;
  }

  .ant-steps-item {
    background: #d1d5db;
    margin-top: 2px;
    margin-right: 4px;
  }

  .ant-steps-item-active {
    margin: 0 2px;
    background: none;
  }

  .ant-steps-navigation .ant-steps-item::before {
    background: #111827;
    transition-timing-function: linear;
    transition: width 0.3s;
    left: 0px;
  }
`;

const StyledStepsTitle = styled('div')`
  text-align: left;
`;

export { StyledContainer, StyledStepsTitle };

import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import AppModal from '../../../components/Modal/AppModal';

interface ICardDetails {
  isModalVisible: boolean;
  handleCancel: () => void;
  handleOk: (id: string) => void;
}

const CardDetails = ({ isModalVisible, handleCancel, handleOk }: ICardDetails) => {
  const stripe = useStripe();
  const elements = useElements();

  const submitPayment = async () => {
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement('card');
    if (cardElement) {
      const result = await stripe.createToken(cardElement);

      if (result.error) {
        console.log(result.error.message);
      } else {
        handleOk(result.token.id);
      }
    }
  };
  return (
    <AppModal
      visible={isModalVisible}
      handleCancel={handleCancel}
      width={600}
      titleText={'Update card details'}
      handleOk={submitPayment}
    >
      <CardElement options={{ hidePostalCode: true }} />
    </AppModal>
  );
};

export default CardDetails;

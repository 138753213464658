import styled from '@emotion/styled';
import Apple from '../../images/apple-store.png';
import Android from '../../images/play-store.png';

const getPlatform = () => {
  const userAgent = navigator.userAgent || navigator.vendor;
  if (/android/i.test(userAgent)) {
    return 'android';
  } else if (/iPad|iPhone|iPod/.test(userAgent)) {
    return 'ios';
  } else {
    return 'desktop';
  }
};

const Container = styled.div`
  text-align: center;
  padding: 20px;
`;

const InstructionText = styled.p`
  margin: 20px 0;
`;

const AppLink = styled.a`
  cursor: pointer;
`;

const AppImage = styled.img`
  cursor: pointer;
`;

const AcceptInvite = () => {
  const platform = getPlatform();

  return (
    <Container>
      <InstructionText>Please click the button below to download the app for your device:</InstructionText>
      {platform === 'android' && (
        <AppLink href="https://play.google.com/store/apps/details?id=com.fitclub.mobile_app">
          <AppImage src={Android} alt="Download on Google Play" />
        </AppLink>
      )}
      {platform === 'ios' && (
        <AppLink href="https://apps.apple.com/us/app/fitclub-your-fitness-club/id6444086436?platform=iphone">
          <AppImage src={Apple} alt="Download on the App Store" />
        </AppLink>
      )}
      <InstructionText>
        After downloading the app, please click on the link in the email again to register.
      </InstructionText>
    </Container>
  );
};

export default AcceptInvite;

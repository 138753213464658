/* eslint-disable no-inline-styles/no-inline-styles */
import { PlusOutlined } from '@ant-design/icons';
import { FormInstance, List, message } from 'antd';
import Papa from 'papaparse';
import { useRef, useState } from 'react';
import { TagsInput } from 'react-tag-input-component';
import { isEmail } from '../../../common/utils';
import Bamboo from '../../../images/bamboo.png';
import FileUpload from '../../../images/file_upload.png';
import MSTeams from '../../../images/ms_teams.png';
import {
  StyledDescription,
  StyledError,
  StyledListContainer,
  StyledTagContainer,
  StyledTitle,
  StyledContainer,
} from './styles';

const allowedExtensions = ['csv'];
interface ITeamMembers {
  form: FormInstance;
}

const TeamMembers = ({ form }: ITeamMembers) => {
  const [tags, setTags] = useState<string[]>(form.getFieldValue('members'));
  const [error, setError] = useState<string>();
  const [fileName, setFileName] = useState<string>();
  const [k, setK] = useState(0);
  const [fileError, setFileError] = useState('');
  const inputFileRef = useRef<HTMLInputElement>(null);

  const validate = (email: string, emailList: string[]) => {
    setError('');
    if (isEmail(email)) {
      return true;
    } else {
      setError('Please enter valid email addresses');
      return false;
    }
  };

  const handleFileChange = (e: any) => {
    setError('');

    if (e.target.files.length > 0) {
      const inputFile: File = e.target.files[0];

      const fileExtension = inputFile?.type.split('/')[1];
      if (!allowedExtensions.includes(fileExtension)) {
        message.error('Invalid file format! Please check the file');
        setError('Invalid file format! Please check the file');
        return;
      }

      const reader: FileReader = new FileReader();

      reader.onload = async ({ target }) => {
        const csv: any = target && target.result && Papa.parse(target.result as string, { header: false });
        console.log(csv.data[0]);
        setFileName(e.target.files[0].name);

        form.setFieldsValue({ members_file: csv.data[0] });
        setTags(csv.data[0] as string[]);
        setK(new Date().getTime());
      };
      reader.readAsText(inputFile);
    }
  };

  const onButtonClick = () => {
    if (inputFileRef && inputFileRef.current) inputFileRef.current.click();
  };
  const data = [
    // {
    //   icon: <img src={Bamboo} />,
    //   title: 'BambooHR [Coming Soon]',
    //   desc: 'Sync your team data between FitClub and BambooHR',
    // },
    // {
    //   icon: <img src={MSTeams} />,
    //   title: 'Microsoft Teams [Coming Soon]',
    //   desc: 'Sync your team data between FitClub and Microsoft Teams',
    // },
    {
      icon: <img src={FileUpload} />,
      title: fileName ? `${fileName} selected` : 'Import .csv file',
      desc: (
        <span>
          Add the list of your team by importing a .csv file (Download a <a href="/sample.csv">sample.csv</a> file)
        </span>
      ),
      component: (
        <>
          <input type="file" id="file" ref={inputFileRef} style={{ display: 'none' }} onChange={handleFileChange} />
          {!fileName ? (
            <PlusOutlined onClick={onButtonClick} />
          ) : (
            <div onClick={onButtonClick} style={{ color: '#4F46E5', position: 'relative', bottom: 20 }}>
              Change
            </div>
          )}
        </>
      ),
    },
  ];

  return (
    <StyledContainer>
      <StyledListContainer>
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta avatar={item.icon} title={item.title} description={item.desc} />
              {item.component}
            </List.Item>
          )}
        />
      </StyledListContainer>

      <StyledTitle>Add your team manually</StyledTitle>
      <StyledDescription>Type work email addresses of the team mates seperated with a comma.</StyledDescription>

      <StyledTagContainer>
        <TagsInput
          seprators={[',']}
          value={tags}
          key={k}
          onChange={setTags}
          beforeAddValidate={validate}
          name="members"
          onBlur={() => form.setFieldsValue({ members: tags })}
        />
        <StyledError>{error}</StyledError>
      </StyledTagContainer>
    </StyledContainer>
  );
};

export default TeamMembers;

import axios from 'axios';
import { ChallengeRequest, UpdateChallengeRequest } from '../features/Challenge/context/ChallengeProvider';
import { LoginRequest } from '../features/Login/context/LoginProvider';
import { RegisterCustomerRequest, SubscribeCustomerRequest } from '../features/Onboard/context/OnboardProvider';
import { ChangeOTPPasswordRequest, SignupRequest } from '../features/Onboard/Signup/context/SignupProvider';
import { ResetCustomerPasswordRequest } from '../features/ResetCustomerPassword/context/ChangeCustomerPasswordContext';
import { ResetPasswordRequest } from '../features/ResetPassword/context/ChangePasswordContext';
import { ChangePasswordRequest } from '../features/Settings/context/CustomerProvider';

const client = axios.create({
  baseURL: process.env.REACT_APP_BE_URL,
  // headers: {
  //   Authorization: localStorage.getItem('token') as string,
  // },
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

client.interceptors.request.use((config) => {
  if (config && config.headers) {
    config.headers['Authorization'] = localStorage.getItem('token') as string;
    return config;
  }
});

client.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      localStorage.clear();
    }
    return Promise.reject(error);
  }
);

const signupApi = async (signupRequest: SignupRequest) => {
  const response = await client.post('/admin-operations/admin/signup', signupRequest);

  return response;
};

const registerCustomerApi = async (registerCustomerRequest: RegisterCustomerRequest) => {
  const response = await client.post('/admin-operations/admin/register-customer', registerCustomerRequest);

  return response;
};

const subscribeCustomerApi = async (subscribeCustomerRequest: SubscribeCustomerRequest) => {
  const response = await client.put('/admin-operations/admin/subscribe', subscribeCustomerRequest);

  return response;
};

const loadTemplatesApi = async (workoutType: string) => {
  const response = await client.get('/admin-operations/admin/challenge-template', {
    params: { workoutType: workoutType },
  });

  return response;
};

const addChallengeApi = async (challengeRequest: ChallengeRequest) => {
  const response = await client.post('/admin-operations/admin/company-challenge', challengeRequest);

  return response;
};

const loadCompanyChallengesApi = async () => {
  const response = await client.get('/admin-operations/admin/challenges');

  return response;
};

const loadCompanyChallengeDetailsApi = async (challegeId: string) => {
  const response = await client.get(`/admin-operations/admin/challenge/${challegeId}`);

  return response;
};

const loadCustomerDetailsApi = async () => {
  const response = await client.get(`/admin-operations/admin/customer`);

  return response;
};

const loginApi = async (loginRequest: LoginRequest) => {
  const response = await client.post('/admin-operations/admin/login', loginRequest);

  return response;
};

const mauChangePasswordApi = async (cpRequest: ResetPasswordRequest) => {
  const response = await client.post('/user-operations/user/change-password', cpRequest);

  return response;
};

const getStatsApi = async (month: number) => {
  const response = await client.get('/admin-operations/admin/stats', { params: { month: month } });

  return response;
};

const getMonthChallengeDetailsApi = async (month: number) => {
  const response = await client.get(`/admin-operations/admin/challenges/month/${month}`);

  return response;
};

const loadLeaderboardApi = async (month: number) => {
  const response = await client.get(`/admin-operations/admin/leaderboard/${month}`);

  return response;
};

const unsubscribeCustomerApi = async (reason: string) => {
  const response = await client.put('/admin-operations/admin/unsubscribe', { reason: reason });

  return response;
};

const changePasswordApi = async (data: ChangePasswordRequest) => {
  const response = await client.put('/admin-operations/admin/changePassword', {
    currentPassword: data.currentPassword,
    newPassword: data.newPassword,
  });

  return response;
};

const verifyEmailApi = async (otp: string, email: string) => {
  const response = await client.put('/admin-operations/admin/verifyEmail', { otp: otp, email: email });

  return response;
};

const changeProductApi = async (productId: string) => {
  const response = await client.put('/admin-operations/admin/updateProduct', { productId: productId });

  return response;
};

const changePasswordWithOTPApi = async (changePasswordOTPRequest: ChangeOTPPasswordRequest) => {
  const response = await client.put('/admin-operations/admin/changePasswordWithOTP', changePasswordOTPRequest);

  return response;
};

const deleteChallengeApi = async (challengeId: string) => {
  const response = await client.delete(`/admin-operations/admin/delete-challenge/${challengeId}`);

  return response;
};

const updateChallengeApi = async (updateChallengeRequest: UpdateChallengeRequest, challegeId: string) => {
  const response = await client.put(`/admin-operations/admin/company-challenge/${challegeId}`, updateChallengeRequest);

  return response;
};

const updateAdminsApi = async (admins: string[]) => {
  const response = await client.put(`/admin-operations/admin/update-admins`, { admins: admins });

  return response;
};

const getActiveUsersApi = async (month: number) => {
  const response = await client.get(`/admin-operations/admin/activeUsers/${month}`);
  return response;
};

const changeCardApi = async (tokenId: string) => {
  const response = await client.put('/admin-operations/admin/updateCard', { tokenId: tokenId });

  return response;
};

const requestChangePasswordApi = async (email: string) => {
  const response = await client.post('/admin-operations/admin/forgotPassword', { email: email });

  return response;
};

const changeCustomerPasswordApi = async (cpRequest: ResetCustomerPasswordRequest) => {
  const response = await client.post('/admin-operations/admin/changeForgotPassword', cpRequest);

  return response;
};

const updateMembersApi = async (members: string[]) => {
  const response = await client.put(`/admin-operations/admin/update-team`, { members: members });

  return response;
};

const removeUserApi = async (userId: string) => {
  const response = await client.put(`/admin-operations/admin/removeUser/${userId}`);

  return response;
};

const requestEnterpriseProductApi = async () => {
  const response = await client.get(`/admin-operations/admin/requestEnterpriseProduct`);

  return response;
};

const getAvgStepsApi = async (startdate: string, endDate: string) => {
  const response = await client.get(`/admin-operations/admin/avgsteps`, {
    params: { startDate: startdate, endDate: endDate },
  });
  return response;
};

const getStepsForRangeApi = async (startdate: string, endDate: string) => {
  const response = await client.get(`/admin-operations/admin/stepsForRange`, {
    params: { startDate: startdate, endDate: endDate },
  });
  return response;
};

const getInvitationDetailsApi = async (invitationCode: string) => {
  const response = await client.get(`/admin-operations/admin/pre-invite/${invitationCode}`);

  return response;
};

const getInvitedProductDetailsApi = async () => {
  const response = await client.get(`/admin-operations/admin/invited-product`);

  return response;
};

export {
  signupApi,
  registerCustomerApi,
  subscribeCustomerApi,
  loadTemplatesApi,
  addChallengeApi,
  loadCompanyChallengesApi,
  loadCompanyChallengeDetailsApi,
  loadCustomerDetailsApi,
  loginApi,
  getStatsApi,
  getMonthChallengeDetailsApi,
  loadLeaderboardApi,
  unsubscribeCustomerApi,
  changePasswordApi,
  verifyEmailApi,
  changeProductApi,
  changePasswordWithOTPApi,
  deleteChallengeApi,
  updateChallengeApi,
  updateAdminsApi,
  mauChangePasswordApi,
  getActiveUsersApi,
  changeCardApi,
  requestChangePasswordApi,
  changeCustomerPasswordApi,
  updateMembersApi,
  removeUserApi,
  requestEnterpriseProductApi,
  getAvgStepsApi,
  getStepsForRangeApi,
  getInvitationDetailsApi,
  getInvitedProductDetailsApi,
};

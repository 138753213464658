import styled from '@emotion/styled';
import { Col, Divider, Modal } from 'antd';

const AppModalContainer = styled(Modal)`
  .ant-modal-footer {
    text-align: center;
    border-top: 0 none;
    padding-bottom: 32px;
  }

  .ant-modal-header {
    padding: 24px 24px 0px 24px;
    border-bottom: 0 none;
  }

  .ant-modal-title {
    font-size: 16px;
  }
`;

const HeaderSection = styled('div')`
  margin-top: 24px;
  font-size: 14px;
  font-weight: 400;
  color: #585e6d;
  line-height: 170%;

  .ant-image {
    margin-bottom: -24px;
  }
`;

const AddAnother = styled('div')`
  margin-top: 25px;
`;

const StyledModalDivider = styled(Divider)`
  margin: 0 0 24px;
`;

const ModalSectionTitle = styled(Col)`
  font-weight: 500;
  font-size: 14px;
  color: #0e1936;
`;

export { AppModalContainer, HeaderSection, AddAnother, StyledModalDivider, ModalSectionTitle };

import styled from '@emotion/styled';
import { Col, Layout, Row, Tag } from 'antd';

const StyledContainer = styled('div')`
  .ant-layout-sider {
    background: #f5f5f5;
  }

  .ant-layout {
    background: #f5f5f5;
  }

  .ant-menu {
    background: #f5f5f5;
  }

  // .ant-spin-container {
  //   height: 20vh !important;
  //   position: relative;
  // }

  font-family: 'Lexend';
  font-style: normal;
`;

const StyledContent = styled('div')`
  padding: 20px;
  min-width: 50vw;
`;

const StyledDetailsContent = styled('div')`
  background: white;
  padding: 20px;
  margin: 20px;
  width: 110%;

  text-align: left;
`;

const StyledProductName = styled('div')`
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;

  padding: 5px 0;
`;

const StyledInfoDetails = styled('div')`
  padding-bottom: 10px;
`;

const StyledInfoTitle = styled('div')`
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  /* identical to box height, or 175% */

  /* gray/900 */

  color: #111827;

  padding-bottom: 10px;
`;

const StyledTableHeader = styled('div')`
  padding-bottom: 10px;
  float: right;
`;

const StyledLinkText = styled('span')`
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* indigo/600 */

  color: #4f46e5;

  padding-left: 5px;
`;

const StyledNoMembers = styled('div')`
  padding: 20px;
`;

export {
  StyledContent,
  StyledDetailsContent,
  StyledContainer,
  StyledProductName,
  StyledInfoDetails,
  StyledInfoTitle,
  StyledTableHeader,
  StyledLinkText,
  StyledNoMembers,
};

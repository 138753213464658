import styled from '@emotion/styled';

const StyledOption = styled('div')`
  padding: 5px 0;
  text-align: left;
  border-radius: 12px;

  border: 1px solid #f9fafb;
  box-shadow: 0px 2px 23px rgba(0, 0, 0, 0.08);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;

  margin: 10px 0;
`;

const StyledProductContainer = styled('div')`
  text-align: center;
`;

const StyledProductName = styled('div')`
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;

  color: #111827;

  padding-bottom: 5px;
`;

const StyledPrice = styled('span')`
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 30px;

  padding: 5px 0;

  color: #4f46e5;
`;

const StyledProductDescription = styled('div')`
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* gray/500 */

  color: #6b7280;
`;

export { StyledOption, StyledProductContainer, StyledProductName, StyledPrice, StyledProductDescription };

import styled from '@emotion/styled';
import { Button } from 'antd';
import { SimpleButtonProps } from './SimpleButton.types';

const StyledContainer = styled.div`
  .ant-btn > span {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
  }
`;

const SimpleButton = ({
  children,
  htmlType = 'button',
  type,
  size = 'large',
  shape = 'default',
  block = false,
  loading = false,
  disabled = false,
  icon,
  onClick,
  className,
  dataTestId,
}: SimpleButtonProps) => {
  return (
    <StyledContainer>
      <Button
        htmlType={htmlType}
        type={type === 'alt' ? undefined : type === 'alt-borderless' ? 'link' : type}
        size={size}
        shape={shape}
        block={block}
        loading={loading}
        disabled={disabled}
        icon={icon}
        onClick={onClick}
        data-testid={dataTestId}
        className={className}
      >
        {children}
      </Button>
    </StyledContainer>
  );
};

export default SimpleButton;

import styled from '@emotion/styled';
import { Avatar, Col } from 'antd';

type TextProps = {
  isactive: boolean;
};

const StyledCard = styled.div`
  // background: #ffffff;
  width: 100%;
`;

const StyledChallengeName = styled.div<TextProps>`
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  padding-bottom: 8px;
  /* identical to box height, or 129% */

  letter-spacing: -0.408px;

  /* gray/700 */

  color: ${(props) => (props.isactive ? '#fff' : `#374151`)};
`;

const StyledPreText = styled.div<TextProps>`
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 18px;
  padding-bottom: 4px;
  /* identical to box height, or 164% */

  letter-spacing: -0.078px;

  /* indigo/400 */

  color: ${(props) => (props.isactive ? '#E0E7FF' : `#818cf8`)};
`;

const StyledTextCol = styled(Col)`
  text-align: left;
`;

const StyledDateText = styled.div<TextProps>`
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  /* identical to box height, or 138% */

  letter-spacing: -0.078px;

  /* Red */

  color: ${(props) => (props.isactive ? '#fff' : `#eb5757`)};
`;

const StyledAvatarCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledChavronCol = styled(Col)<TextProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.isactive ? '#fff' : `#000`)};
`;

const StyledAvatar = styled(Avatar)`
  background: #eef2ff;
  color: #4338ca;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export {
  StyledCard,
  StyledPreText,
  StyledChallengeName,
  StyledTextCol,
  StyledDateText,
  StyledAvatarCol,
  StyledChavronCol,
  StyledAvatar,
};

import { Col, Row } from 'antd';
import { CircularProgressbar, CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { StyledRow, StyledChart, StyledChartTitle, StyledCol } from './styles';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import HowToRegIcon from '@mui/icons-material/HowToReg';
export interface IChallengeAnalytics {
  numberOfCompanyUsers: number;
  numberOfChallengeParticipants: number;
  challengeParticipantPercentage: number;
  averageDailyPoints: number;
  workoutUnit: string;
  completedUsersPercentage: number;
  challengeWorkoutPoints: number;
}

const gutterh = 16;
const gutterv = 24;

const ChallengeAnalytics = ({
  numberOfCompanyUsers,
  numberOfChallengeParticipants,
  challengeParticipantPercentage,
  averageDailyPoints,
  workoutUnit,
  completedUsersPercentage,
  challengeWorkoutPoints,
}: IChallengeAnalytics) => {
  return (
    <StyledRow gutter={[gutterh, gutterv]}>
      <StyledCol span={8}>
        <StyledChart>
          <CircularProgressbarWithChildren
            value={challengeParticipantPercentage}
            styles={buildStyles({
              pathColor: `#FCBB64`,
            })}
          >
            <EmojiEmotionsIcon fontSize="large" />
          </CircularProgressbarWithChildren>
        </StyledChart>

        <StyledChartTitle>
          {numberOfChallengeParticipants} of {numberOfCompanyUsers}
        </StyledChartTitle>
        <div className="stat-label">Participated Users</div>
      </StyledCol>
      <StyledCol span={8}>
        <StyledChart>
          <CircularProgressbarWithChildren
            value={averageDailyPoints ? (averageDailyPoints / challengeWorkoutPoints) * 100 : 0}
            styles={buildStyles({
              pathColor: `#F98A6B`,
            })}
          >
            <AccessibilityIcon fontSize="large" />
          </CircularProgressbarWithChildren>
        </StyledChart>

        <StyledChartTitle>
          {averageDailyPoints} {workoutUnit}
        </StyledChartTitle>
        <div className="stat-label">Individual Daily Avg</div>
      </StyledCol>
      <StyledCol span={8}>
        <StyledChart>
          <CircularProgressbarWithChildren
            value={completedUsersPercentage}
            styles={buildStyles({
              pathColor: `#6FE4F1`,
            })}
          >
            <HowToRegIcon fontSize="large" />
          </CircularProgressbarWithChildren>
        </StyledChart>

        <StyledChartTitle>{completedUsersPercentage} %</StyledChartTitle>
        <div className="stat-label">No of users that’ve completed the challenge</div>
      </StyledCol>
    </StyledRow>
  );
};

export default ChallengeAnalytics;

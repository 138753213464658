import styled from '@emotion/styled';
import { Form, Row } from 'antd';
import { Header } from 'antd/lib/layout/layout';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #eef2ff;
  height: 80vh;
`;

const StyledTitle = styled.div`
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  /* identical to box height, or 100% */

  /* gray/900 */

  color: #111827;

  text-align: left;
  padding-bottom: 30px;
`;

const StyledContent = styled.div`
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* or 157% */

  /* gray/800 */

  color: #1f2937;

  text-align: left;
  padding-bottom: 15px;
`;

const StyledHeader = styled(Header)`
  width: 100%;
  background: #eef2ff;
  display: flex;
  justify-content: space-between;

  border-bottom: 1px solid #d1d5db;
`;

const StyledLogo = styled.div`
  display: flex;
  align-items: center;

  font-family: 'Lexend';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 41px;
  /* identical to box height, or 228% */

  letter-spacing: 0.374px;

  /* Label Color/Light/Primary */

  color: #000000;

  a {
    color: #000;
  }
`;

const StyledSection = styled.div`
  background: #fff;
  width: 40vw;
  padding: 30px;
`;

export { StyledContainer, StyledTitle, StyledContent, StyledHeader, StyledLogo, StyledSection };

import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { Navigate, Outlet } from 'react-router-dom';
import Header from '../../Header';
import { StyledLayout } from './styles';

const AuthLayout = () => {
  if (!localStorage.getItem('token')) {
    return <Navigate to="/login" />;
  }
  return (
    // eslint-disable-next-line no-inline-styles/no-inline-styles
    <Layout style={{ background: '#F5F5F5' }}>
      <Header />
      <StyledLayout>
        <Content>
          <Outlet />
        </Content>
      </StyledLayout>
    </Layout>
  );
};

export default AuthLayout;

import styled from '@emotion/styled';
import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';

const StyledLayout = styled(Layout)`
  height: 100%;
  min-height: 100vh;
`;

const StyledContent = styled(Content)`
  // padding: 76px 300px 76px 300px;
  background: #eef2ff;
`;

export { StyledContent, StyledLayout };

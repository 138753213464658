import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { Outlet } from 'react-router-dom';
import { StyledContent, StyledLayout } from './styles';

const UnAuthLayout = () => {
  return (
    <StyledLayout>
      <StyledContent>
        <Outlet />
      </StyledContent>
    </StyledLayout>
  );
};

export default UnAuthLayout;

import styled from '@emotion/styled';
import { Avatar, Col, Row } from 'antd';

const StyledTopRow = styled(Row)`
  padding: 20px 20px 40px 20px;
  text-align: left;
`;

const StyledTopRowHeader = styled('div')`
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */

  /* gray/400 */

  color: #9ca3af;
`;

const StyledTopRowText = styled('div')`
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  /* gray/700 */

  color: #374151;
`;

const StyledContentHeader = styled(Row)`
  padding: 10px 20px;
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */

  /* gray/900 */

  color: #111827;
`;

const StyledContents = styled(Row)`
  text-align: left;
  padding: 10px 20px;
`;

const StyledCol = styled(Col)`
  border-left: 1px solid #e5e7eb;
  padding-left: 5px;
`;

const StyledAvatar = styled(Avatar)`
  background: #fff;
  border: 1px solid #e5e7eb;

  .action_icon {
    color: #1f2937;
  }
`;

export {
  StyledTopRowHeader,
  StyledTopRowText,
  StyledTopRow,
  StyledContentHeader,
  StyledContents,
  StyledCol,
  StyledAvatar,
};

import styled from '@emotion/styled';
import { Layout } from 'antd';

const StyledLayout = styled(Layout)`
  // padding: 0 200px;
  margin: 0 auto;
  background: #f5f5f5;
  height: 100%;
  min-height: 100vh;
  max-width: 1168px;
  .primaryBtn {
    background: #4338ca;
    width: 188px;
    border: 1px solid #4338ca;
    /* drop-shadow/button-primary */

    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 4px;
  }

  .secondaryBtn {
    width: 244px;
  }

  .contentTitle {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    /* identical to box height, or 100% */

    /* gray/900 */

    color: #111827;
  }
`;

export { StyledLayout };

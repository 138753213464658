import styled from '@emotion/styled';
import { Col, Radio, Row } from 'antd';

type ColProps = {
  color: string;
  border: boolean;
};

const StyledTitle = styled('div')`
  padding: 5px 0;
`;

const StyledRadio = styled(Radio.Group)`
  width: 100%;
`;

const StyledRow = styled(Row)`
  padding: 10px 0;
`;

const StyledTemplateTitle = styled('div')`
  padding: 50px 0 20px 0;
`;

const StyledCard = styled.div<ColProps>`
  padding: 10px;
  background: ${(props) => (props.color ? props.color : `red`)};
  border-radius: 8px;
  margin: 10px;
  width: 250px;
  height: 140px;
  border: ${(props) => (props.border ? '1px solid #E5E7EB;' : 'none')};

  .name {
    padding: 5px 0;
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  }
`;

const StyledCardContent = styled('div')`
  padding: 5px 0;
  font-family: 'Lexend';
  font-style: normal;
  font-size: 14px;
`;

export { StyledTitle, StyledRadio, StyledRow, StyledTemplateTitle, StyledCard, StyledCardContent };

import 'antd/dist/antd.css';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import AuthLayout from './components/Layout/AuthLayout';
import UnAuthLayout from './components/Layout/UnAuthLayout';
import Challenge from './features/Challenge';
import { ChallengeProvider } from './features/Challenge/context/ChallengeProvider';
import CreateChallenge from './features/Challenge/CreateChallenge';
import Onboard from './features/Onboard';
import { OnboardProvider } from './features/Onboard/context/OnboardProvider';
import GetBack from './features/Onboard/GetBack';
import MultipleAdmin from './features/Onboard/MultipleAdmin';
import SignupWrapper from './features/Onboard/Signup';
import Subscription from './features/Onboard/Subscription';
import Settings from './features/Settings';
import { CustomerProvider } from './features/Settings/context/CustomerProvider';
import '@fontsource/lexend';
import LoginWrapper from './features/Login';
import MauResetPasswordWrapper from './features/ResetPassword';
import Dashboard from './features/Dashboard';
import { LeaderboardProvider } from './features/Leaderboard/context/LeaderboardProvider';
import LeaderBoard from './features/Leaderboard';
import { Spin } from 'antd';
import { useContext } from 'react';
import { GlobalContext } from './context/GlobalProvider';
import VerifyEmailMessage from './features/Onboard/VerifyEmailMessage';
import VerifyEmail from './features/Onboard/VerifyEmail';
import ChangePasswordOTP from './features/Onboard/ChangePasswordOTP';
import Reports from './features/Reports';
import { DashboardProvider } from './features/Dashboard/context/DashboardProvider';
import { ReportProvider } from './features/Reports/context/ReportProvider';
import ResetCustomerPasswordWrapper from './features/ResetCustomerPassword';
import VerifyEmailError from './features/Onboard/VerifyEmailMessage/VerifyEmailError';
import AcceptInvite from './features/AcceptInvite';

function App() {
  const { shouldSpin } = useContext(GlobalContext);
  return (
    <div className="App">
      <Spin size="large" spinning={shouldSpin}>
        <Routes>
          <Route element={<UnAuthLayout />}>
            <Route path="/signup" element={<SignupWrapper />} />
            <Route path="/login" element={<LoginWrapper />} />
            <Route path="/changeAppUserPassword/:otp" element={<MauResetPasswordWrapper />} />
            <Route path="/verifyEmailMessage" element={<VerifyEmailMessage />} />
            <Route path="/verifyEmail/:otp/email/:email" element={<VerifyEmail />} />
            <Route path="/changePasswordWithOTP/:otp/email/:email" element={<ChangePasswordOTP />} />
            <Route path="/changeCustomerPassword/:otp/email/:email" element={<ResetCustomerPasswordWrapper />} />
            <Route path="/acceptInvitation/:invitationId/company/:companyId" element={<AcceptInvite />} />
            <Route path="/verifyEmailError" element={<VerifyEmailError />} />
          </Route>
          <Route element={<AuthLayout />}>
            <Route path="/" element={<Dashboard />} />
            <Route
              path="/onboard/:step"
              element={
                <OnboardProvider>
                  <Onboard />
                </OnboardProvider>
              }
            />
            <Route path="/getback" element={<GetBack />} />
            <Route
              path="/challenge"
              element={
                <ChallengeProvider>
                  <Challenge />
                </ChallengeProvider>
              }
            />
            <Route
              path="/settings"
              element={
                <CustomerProvider>
                  <Settings />
                </CustomerProvider>
              }
            />
            <Route
              path="/leaderboard"
              element={
                <LeaderboardProvider>
                  <LeaderBoard />
                </LeaderboardProvider>
              }
            />
            <Route
              path="/reports"
              element={
                <DashboardProvider>
                  <ReportProvider>
                    <Reports />
                  </ReportProvider>
                </DashboardProvider>
              }
            />
          </Route>
        </Routes>
      </Spin>
    </div>
  );
}

export default App;

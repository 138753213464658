import React, { ReactElement, useContext, useState } from 'react';
import { getMonthChallengeDetailsApi, getStatsApi } from '../../../api/axios-api';
import { ApiStatus } from '../../../common/rootTypes';
import { GlobalContext } from '../../../context/GlobalProvider';

type DashboardContextProp = {
  getStats: ((month: number) => Promise<void>) | null;
  getMonthChallenges: ((month: number) => Promise<void>) | null;
  getStatsAPIStatus: ApiStatus;
  getMonthChallengesAPIStatus: ApiStatus;
  selectedStats: MonthlyStatResponse | null;
  selectedChallenges: MonthlyChallengeResponse[] | null;
};

export const initialState: DashboardContextProp = {
  getStats: null,
  getMonthChallenges: null,
  getStatsAPIStatus: ApiStatus.idle,
  getMonthChallengesAPIStatus: ApiStatus.idle,
  selectedStats: null,
  selectedChallenges: null,
};

export type Contributor = {
  firstName: string;
  lastName: string;
  profilePicture: string;
  value: number;
};

export type MonthlyStatResponse = {
  signupUsers: number;
  challengesCreated: number;
  avgParticipation: number;
  totalDistance: number;
  totalSteps: number;
  totalExerciseMinutes: number;
  totalActiveEnergyBurnt: number;
  distanceContributors: Contributor[];
  stepContributors: Contributor[];
  caloryContributors: Contributor[];
  minuteContributors: Contributor[];
};

export type MonthlyChallengeResponse = {
  challengeId: string;
  name: string;
  totalParticipants: number;
  noOfCompanyUsers: number;
  participantPercentage: number;
  averageDailyPoints: number;
  completedUsersPercentage: number;
};

export const DashboardContext = React.createContext<DashboardContextProp>(initialState);

export const DashboardProvider = (props: { children: ReactElement | ReactElement[] }) => {
  const { setSpinning } = useContext(GlobalContext);

  const { children } = props;
  const [apiStatus, setApiStatus] = useState<ApiStatus>(ApiStatus.idle);
  const [selectedStats, setSelectedStats] = useState<MonthlyStatResponse | null>(null);
  const [selectedChallenges, setSelectedChallenges] = useState<MonthlyChallengeResponse[]>([]);

  const getStats = async (month: number) => {
    setApiStatus(ApiStatus.pending);
    setSpinning && setSpinning(true);

    try {
      const response = await getStatsApi(month);
      if (response.status == 200) {
        setSelectedStats(response.data.body);
        setApiStatus(ApiStatus.succeeded);
      } else {
        //set error code
        setApiStatus(ApiStatus.failed);
      }
      setSpinning && setSpinning(false);
    } catch (error) {
      setSpinning && setSpinning(false);
    }
  };

  const getMonthChallenges = async (month: number) => {
    setApiStatus(ApiStatus.pending);
    setSpinning && setSpinning(true);

    try {
      const response = await getMonthChallengeDetailsApi(month);
      if (response.status == 200) {
        setSelectedChallenges(response.data.body);
        setApiStatus(ApiStatus.succeeded);
      } else {
        //set error code
        setApiStatus(ApiStatus.failed);
      }
      setSpinning && setSpinning(false);
    } catch (error) {
      setSpinning && setSpinning(false);
    }
  };

  const store: DashboardContextProp = {
    getStats,
    getStatsAPIStatus: apiStatus,
    selectedStats: selectedStats,
    getMonthChallenges: getMonthChallenges,
    getMonthChallengesAPIStatus: apiStatus,
    selectedChallenges: selectedChallenges,
  };

  return <DashboardContext.Provider value={store}>{children}</DashboardContext.Provider>;
};

import { Form } from 'antd';
import AppModal from '../../../components/Modal/AppModal';
import MultipleAdmin from '../../Onboard/MultipleAdmin';
import { AdminDetails } from '../context/CustomerProvider';

interface IEditAdmins {
  handleCancel: () => void;
  handleSubmit: (admins: string[]) => void;
  adminList: AdminDetails[];
}

const EditAdmins = ({ handleCancel, adminList, handleSubmit }: IEditAdmins) => {
  const [form] = Form.useForm();

  return (
    <AppModal
      visible={true}
      handleCancel={handleCancel}
      width={600}
      titleText={'Edit Admins list'}
      handleOk={() => handleSubmit(form.getFieldValue('admins'))}
    >
      <MultipleAdmin form={form} admins={adminList.map((a) => a.email)} />
    </AppModal>
  );
};

export default EditAdmins;

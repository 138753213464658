import { StyledDescription, StyledStepsContent, StyledTitle } from './styles';

interface ICreateChallengeHeader {
  children: React.ReactNode;
  title: string;
  description: string;
}
const CreateChallengeHeader = ({ children, title, description }: ICreateChallengeHeader) => {
  return (
    <StyledStepsContent>
      <StyledTitle className="contentTitle">{title}</StyledTitle>
      <StyledDescription>{description}</StyledDescription>
      {children}
    </StyledStepsContent>
  );
};

export default CreateChallengeHeader;

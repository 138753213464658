/* eslint-disable prefer-const */
import { Tag } from 'antd';
import React, { ReactElement, ReactNode, useContext, useState } from 'react';
import { ApiStatus } from '../../../common/rootTypes';
import { SignalFilled, CalendarOutlined } from '@ant-design/icons';
import {
  addChallengeApi,
  deleteChallengeApi,
  loadCompanyChallengeDetailsApi,
  loadCompanyChallengesApi,
  loadTemplatesApi,
  updateChallengeApi,
} from '../../../api/axios-api';
import { GlobalContext } from '../../../context/GlobalProvider';
import { DashboardContext } from '../../Dashboard/context/DashboardProvider';
import moment from 'moment';

type TagDefinition = {
  color: string;
};
const TagDefinitions = new Map<string, TagDefinition>([
  ['Easy', { color: 'default' }],
  ['Intermediate', { color: '#FFF3B6' }],
  ['Daily', { color: '#B6FFCF' }],
]);

export type Template = {
  id: string;
  name: string;
  tags?: ReactNode[] | null;
  description: string;
  timeDuration: number;
  timeUnit: string;
  workoutPoints: number;
  workoutType: string;
  workoutUnit: string;
};

export type TemplateResponse = {
  challengeTemplateId: string;
  createdAt: string;
  instructions: string;
  isActive: boolean;
  name: string;
  timeDuration: number;
  timeUnit: string;
  updateAt: string;
  workoutPoints: number;
  workoutType: string;
  workoutUnit: string;
  tags: string;
};

export type ChallengeRequest = {
  templateId: string | null;
  name: string;
  workoutType: string;
  workoutPoints: number;
  workoutUnit: string;
  startTime: string;
  endTime: string;
  instructions: string;
  rewards: { rewards: string; claims: string };
};

export type UpdateChallengeRequest = {
  name: string;
  workoutPoints: number;
  workoutUnit: string;
  startTime: string;
  endTime: string;
  instructions: string;
  rewards: { rewards: string; claims: string };
};

type ChallengeContextProp = {
  createChallengeStatus: ApiStatus;
  templates: Template[];
  loadTemplates: ((workoutType: string) => Promise<void>) | null;
  addChallenge: ((challengeRequest: ChallengeRequest) => Promise<void>) | null;
  loadCompanyChallenges: (() => Promise<void>) | null;
  companyChallenges: CompanyChallenge[];
  loadCompanyChallengeDetails: ((challengeId: string) => Promise<void>) | null;
  selectedChallenge: ChallengeDetails | null;
  deleteChallenge: ((challengeId: string) => void) | null;
  updateChallenge: ((updateChallengeRequest: UpdateChallengeRequest, challengeId: string) => Promise<void>) | null;
  predefinedTemplates: PredefinedTemplatesType;
};

export type CompanyChallenge = {
  challengeId: string;
  startDate: string;
  endDate: string;
  totalParticipants: number;
  name: string;
};

export type ChallengeDetails = {
  challengeId: string;
  startDate: string;
  endDate: string;
  name: string;
  totalParticipants: number;
  noOfCompanyUsers: number;
  workoutType: string;
  timeDuration: number;
  timeUnit: string;
  workoutPoints: number;
  workoutUnit: string;
  instructions: string;
  rewards: Reward;
  isActive: boolean;
  participantPercentage: number;
  averageDailyPoints: number;
  completedUsersPercentage: number;
  participantStats: ParticipantStats[];
};

export enum ChallengeMode {
  CREATE,
  EDIT,
}

export enum WorkoutType {
  WALKING = 'walking',
  RUNNING = 'running',
  CYCLING = 'cycling',
}

export type ParticipantStats = {
  firstName: string;
  lastName: string;
  profilePicture: string;
  total: number;
  score: number;
};

export type Participant = {
  firstName: string;
  lastName: string;
  profilePicture: string;
};

export type Reward = {
  rewards: string;
  claims: string;
};

export type PredefinedTemplatesType = {
  [WorkoutType.WALKING]: Template[];
  [WorkoutType.RUNNING]: Template[];
  [WorkoutType.CYCLING]: Template[];
};

const predefinedTemplates: PredefinedTemplatesType = {
  [WorkoutType.WALKING]: [
    {
      id: 'pre-temp-walk-1',
      name: 'Average 10K steps a day challenge (1 Month)',
      description: `<p>Why should I do a walking challenge? Well, there are tons of amazing benefits to walking! You can do it practically anywhere, you can make it as easy or as hard as you want, it’s mind-clearing.</p>\n<p>Aim to walk at least <strong>10,000 steps</strong> every day for <strong>30 days.</strong> If you can't quite get there yet, that's OK! Focus instead on walking a little more each day than you're used to. Take a 30-minute walk at least 5 times a week</p>\n<p>The way you walk can make a big difference to how you feel during your time outside and can boost the effects of some time outside. You can make small adjustments to your posture that'll make a big impact.</p>\n<p><strong><em>Neck:</em></strong> Think about the distance between your ear lobes and shoulders—holding your head up improves upper back mobility and stops hunched shoulders.</p>\n<p><strong><em>Arms:</em></strong> Think flowing pendulum—no tense, power walking arms.</p>\n<p><strong><em>Hips:</em></strong> Lift your abdominals in and up to flatten your stomach and work your core.</p>\n<p><strong><em>Feet: </em></strong>Try to have a soft roll through the whole foot, from heel pad to toes. Getting this right will lift your bottom and streamline your thighs.</p>\n<p><strong>You can choose a route of your preference.</strong></p>\n`,
      workoutPoints: 300000,
      workoutType: 'walking',
      workoutUnit: 'steps',
      tags: null,
      timeDuration: 1,
      timeUnit: 'month',
    },
    {
      id: 'pre-temp-walk-2',
      name: 'Average 10K steps a day challenge (1 Week)',
      description: `<p>Why should I do a walking challenge? Well, there are tons of amazing benefits to walking! You can do it practically anywhere, you can make it as easy or as hard as you want, it’s mind-clearing.</p>\n<p>Aim to walk at least <strong>10,000 steps</strong> every day for <strong>7 days.</strong> If you can't quite get there yet, that's OK! Focus instead on walking a little more each day than you're used to. Take a 30-minute walk 5 times a week</p>\n<p>The way you walk can make a big difference to how you feel during your time outside and can boost the effects of some time outside. You can make small adjustments to your posture that'll make a big impact.</p>\n<p><strong><em>Neck:</em></strong> Think about the distance between your ear lobes and shoulders—holding your head up improves upper back mobility and stops hunched shoulders.</p>\n<p><strong><em>Arms:</em></strong> Think flowing pendulum—no tense, power walking arms.</p>\n<p><strong><em>Hips:</em></strong> Lift your abdominals in and up to flatten your stomach and work your core.</p>\n<p><strong><em>Feet: </em></strong>Try to have a soft roll through the whole foot, from heel pad to toes. Getting this right will lift your bottom and streamline your thighs.</p>\n<p><strong>You can choose a route of your preference.</strong></p>\n`,
      workoutPoints: 70000,
      workoutType: 'walking',
      workoutUnit: 'steps',
      tags: null,
      timeDuration: 1,
      timeUnit: 'week',
    },
    {
      id: 'pre-temp-walk-3',
      name: '10K steps one day challenge',
      description: `<p>Let’s get moving for a day.<br>Keep <strong>10,000</strong> steps today.</p>\n<p>If you can't quite get there yet, that's OK! Let’s try to keep 10K steps today, and maybe you can continue it the next day onwards. Because it’s about taking a step from your comfort zone to build a good health habit.</p>\n<p>The way you walk can make a big difference to how you feel during your time outside and can boost the effects of some time outside. You can make small adjustments to your posture that'll make a big impact.</p>\n<p><strong><em>Neck:</em></strong> Think about the distance between your ear lobes and shoulders—holding your head up improves upper back mobility and stops hunched shoulders.</p>\n<p><strong><em>Arms:</em></strong> Think flowing pendulum—no tense, power walking arms.</p>\n<p><strong><em>Hips:</em></strong> Lift your abdominals in and up to flatten your stomach and work your core.</p>\n<p><strong><em>Feet:</em></strong> Try to have a soft roll through the whole foot, from heel pad to toes. Getting this right will lift your bottom and streamline your thighs.</p>\n<p><strong>You can choose a route of your preference.</strong></p>\n`,
      workoutPoints: 10000,
      workoutType: 'walking',
      workoutUnit: 'steps',
      tags: null,
      timeDuration: 1,
      timeUnit: 'month',
    },
  ],
  [WorkoutType.RUNNING]: [
    {
      id: 'pre-temp-run-1',
      name: 'Daily 2-mile running challenge (1 Month)',
      description: `<p>Running just few minutes each day at a moderate pace may help reduce your risk of from heart attacks, strokes, and other common diseases.</p>\n<p>Aim to jog/run at least <strong>2-mile every day</strong> through out the challenge period.&nbsp;</p>\n<p>Start slowly and increase your pace and distance.</p>\n<p>Make sure you have appropriate running shoes.</p>\n<p>Warm up before you run and stretch after.</p>\n<p>Run with proper form to avoid any injuries.</p>\n<p><strong>You can choose a route of your preference.</strong></p>\n`,
      workoutPoints: 150000,
      workoutType: 'running',
      workoutUnit: 'distance',
      tags: null,
      timeDuration: 1,
      timeUnit: 'month',
    },
    {
      id: 'pre-temp-run-2',
      name: 'Daily 2-mile running challenge (1 Week)',
      description: `<p>Running just few minutes each day at a moderate pace may help reduce your risk of from heart attacks, strokes, and other common diseases.</p>\n<p>Aim to jog/run at least <strong>2-mile every day</strong> through out the challenge period.&nbsp;</p>\n<p>Start slowly and increase your pace and distance.</p>\n<p>Make sure you have appropriate running shoes.</p>\n<p>Warm up before you run and stretch after.</p>\n<p>Run with proper form to avoid any injuries.</p>\n<p><strong>You can choose a route of your preference.</strong></p>\n`,
      workoutPoints: 35000,
      workoutType: 'running',
      workoutUnit: 'distance',
      tags: null,
      timeDuration: 1,
      timeUnit: 'week',
    },
    {
      id: 'pre-temp-run-3',
      name: 'Run for a cause',
      description: `<p>Running is a great exercise to get yourself active. And running for a cause makes it even more valuable. Let’s join for this charity run.</p>\n<p>Aim to jog/run and complete <strong>2 miles</strong> of this one time charity event.&nbsp;</p>\n<p>Start slowly and increase your pace and distance.</p>\n<p>Make sure you have appropriate running shoes.</p>\n<p>Warm up before you run and stretch after.</p>\n<p>Run with proper form to avoid any injuries.</p>\n<p><strong>You can choose a route of your preference.</strong></p>\n`,
      workoutPoints: 5000,
      workoutType: 'running',
      workoutUnit: 'distance',
      tags: null,
      timeDuration: 1,
      timeUnit: 'day',
    },
  ],
  [WorkoutType.CYCLING]: [
    {
      id: 'pre-temp-cycle-1',
      name: 'Daily 10-mile cycling challenge (1 Month)',
      description: `<p>Riding a bike is healthy, fun and a low-impact form of exercise for all ages.</p>\n<p>Cycling is easy to fit into your daily routine by riding to the shops, park, school or work.</p>\n<p>Cycling just few minutes each day at a moderate pace may help reduce your risk of from heart attacks, strokes, and other common diseases.</p>\n<p>Aim to cycle at least <strong>10-mile</strong> every day through out the challenge period.&nbsp;</p>\n<p>Start slowly and increase your pace and distance.</p>\n<p>Make sure you have appropriate  shoes.</p>\n<p>Warm up before you cycle and stretch after.</p>\n<p><strong>You can choose a route of your preference.</strong></p>\n`,
      workoutPoints: 450000,
      workoutType: 'cycling',
      workoutUnit: 'distance',
      tags: null,
      timeDuration: 1,
      timeUnit: 'month',
    },
    {
      id: 'pre-temp-cycle-2',
      name: 'Daily 10-mile cycling challenge (1 Week)',
      description: `<p>Let’s get moving. Go out side, cycle.</p>\n<p>Riding a bike is healthy, fun and a low-impact form of exercise for all ages.</p>\n<p>Cycling is easy to fit into your daily routine by riding to the shops, park, school or work.</p>\n<p>Cycling just few minutes each day at a moderate pace may help reduce your risk of from heart attacks, strokes, and other common diseases.</p>\n<p>Aim to cycle at least <strong>10-mile</strong> every day through out the challenge period.&nbsp;</p>\n<p>Start slowly and increase your pace and distance.</p>\n<p>Make sure you have appropriate  shoes.</p>\n<p>Warm up before you cycle and stretch after.</p>\n<p><strong>You can choose a route of your preference.</strong></p>\n`,
      workoutPoints: 105000,
      workoutType: 'cycling',
      workoutUnit: 'distance',
      tags: null,
      timeDuration: 1,
      timeUnit: 'week',
    },
    {
      id: 'pre-temp-cycle-3',
      name: 'Cycle for a cause',
      description: `<p>Cycling is a great exercise to get yourself active. And cycling for a cause makes it even more valuable. Let’s join for this charity event.</p>\n<p>Riding a bike is healthy, fun and a low-impact form of exercise for all ages.</p>\n<p>Cycling is easy to fit into your daily routine by riding to the shops, park, school or work.</p>\n<p>Cycling just few minutes each day at a moderate pace may help reduce your risk of from heart attacks, strokes, and other common diseases.</p>\n<p>Aim to complete <strong>10-mile</strong> target to complete the challenge.&nbsp;</p>\n<p>Start slowly and increase your pace and distance.</p>\n<p>Make sure you have appropriate  shoes.</p>\n<p>Warm up before you cycle and stretch after.</p>\n<p><strong>You can choose a route of your preference.</strong></p>\n`,
      workoutPoints: 15000,
      workoutType: 'cycling',
      workoutUnit: 'distance',
      tags: null,
      timeDuration: 1,
      timeUnit: 'day',
    },
  ],
};

export const initialState: ChallengeContextProp = {
  createChallengeStatus: ApiStatus.idle,
  templates: [],
  loadTemplates: null,
  addChallenge: null,
  loadCompanyChallenges: null,
  companyChallenges: [],
  loadCompanyChallengeDetails: null,
  selectedChallenge: null,
  deleteChallenge: null,
  updateChallenge: null,
  predefinedTemplates: predefinedTemplates,
};

export const ChallengeContext = React.createContext<ChallengeContextProp>(initialState);

export const ChallengeProvider = (props: { children: ReactElement | ReactElement[] }) => {
  const { setSpinning } = useContext(GlobalContext);
  const { getStats, getMonthChallenges } = useContext(DashboardContext);

  const { children } = props;
  const [apiStatus, setApiStatus] = useState<ApiStatus>(ApiStatus.idle);
  const [templates, setTemplates] = useState<Template[]>([]);
  const [companyChallenges, setCompanyChallenges] = useState<CompanyChallenge[]>([]);
  const [selectedChallenge, setSelectedChallenge] = useState<ChallengeDetails | null>(null);

  const loadTemplates = async (workoutType: string) => {
    setSpinning && setSpinning(true);

    setApiStatus(ApiStatus.pending);

    try {
      let response = await loadTemplatesApi(workoutType);
      if (response.status == 200) {
        const returnedTemplates: TemplateResponse[] = response.data.body;
        const mappedTemplates = returnedTemplates.map((returnedTemplate: TemplateResponse) => {
          const returnedTags =
            returnedTemplate.tags && returnedTemplate.tags.length > 0 ? returnedTemplate.tags.split(',') : null;
          return {
            id: returnedTemplate.challengeTemplateId,
            name: returnedTemplate.name,
            description: returnedTemplate.instructions,
            tags:
              returnedTags && returnedTags.length > 0
                ? returnedTags.map((t, i) => (
                    <Tag key={i} icon={<SignalFilled />} color={TagDefinitions.get(t)?.color}>
                      {t}
                    </Tag>
                  ))
                : null,
            timeDuration: returnedTemplate.timeDuration,
            timeUnit: returnedTemplate.timeUnit,
            workoutPoints: returnedTemplate.workoutPoints,
            workoutType: returnedTemplate.workoutType,
            workoutUnit: returnedTemplate.workoutUnit,
          };
        });

        // Custome challenge
        mappedTemplates.push({
          id: '-1',
          name: 'Create a custom Challenge',
          description: 'Create your own challenge for your company’s FitClub.',
          timeDuration: 0,
          timeUnit: '',
          workoutPoints: 0,
          workoutType: '',
          workoutUnit: '',
          tags: [],
        });
        setTemplates(mappedTemplates);
        setApiStatus(ApiStatus.succeeded);
      } else {
        //set error code
        setApiStatus(ApiStatus.failed);
      }
      setSpinning && setSpinning(false);
    } catch (error) {
      setSpinning && setSpinning(false);
    }
  };

  const addChallenge = async (challengeRequest: ChallengeRequest) => {
    setSpinning && setSpinning(true);

    setApiStatus(ApiStatus.pending);

    try {
      let response = await addChallengeApi(challengeRequest);
      if (response.status == 200) {
        setApiStatus(ApiStatus.succeeded);
      } else {
        //set error code
        setApiStatus(ApiStatus.failed);
      }
      setSpinning && setSpinning(false);
      loadCompanyChallenges();
      const currentMonth = Number(moment().format('M'));

      getStats && getStats(currentMonth);
      getMonthChallenges && getMonthChallenges(currentMonth);
    } catch (error) {
      setSpinning && setSpinning(false);
    }
  };

  const loadCompanyChallenges = async () => {
    setSpinning && setSpinning(true);

    setApiStatus(ApiStatus.pending);

    try {
      let response = await loadCompanyChallengesApi();
      if (response.status == 200) {
        const companyChallenges: CompanyChallenge[] = response.data.body;

        setCompanyChallenges(companyChallenges);
        setApiStatus(ApiStatus.succeeded);
      } else {
        //set error code
        setApiStatus(ApiStatus.failed);
      }
      setSpinning && setSpinning(false);
    } catch (error) {
      setSpinning && setSpinning(false);
    }
  };

  const loadCompanyChallengeDetails = async (challengeId: string) => {
    setSpinning && setSpinning(true);

    setApiStatus(ApiStatus.pending);

    try {
      let response = await loadCompanyChallengeDetailsApi(challengeId);
      if (response.status == 200) {
        setSelectedChallenge(response.data.body);
        setApiStatus(ApiStatus.succeeded);
      } else {
        //set error code
        setApiStatus(ApiStatus.failed);
      }
      setSpinning && setSpinning(false);
    } catch (error) {
      setSpinning && setSpinning(false);
    }
  };

  const deleteChallenge = async (challengeId: string) => {
    setSpinning && setSpinning(true);

    setApiStatus(ApiStatus.pending);

    try {
      let response = await deleteChallengeApi(challengeId);
      if (response.status == 200) {
        setApiStatus(ApiStatus.succeeded);
      } else {
        //set error code
        setApiStatus(ApiStatus.failed);
      }
      setSpinning && setSpinning(false);
      loadCompanyChallenges();
      setSelectedChallenge(null);
    } catch (error) {
      setSpinning && setSpinning(false);
    }
  };

  const updateChallenge = async (updateChallengeRequest: UpdateChallengeRequest, challengeId: string) => {
    setSpinning && setSpinning(true);

    setApiStatus(ApiStatus.pending);

    try {
      let response = await updateChallengeApi(updateChallengeRequest, challengeId);
      if (response.status == 200) {
        setApiStatus(ApiStatus.succeeded);
      } else {
        //set error code
        setApiStatus(ApiStatus.failed);
      }
      setSpinning && setSpinning(false);
      loadCompanyChallenges();
      loadCompanyChallengeDetails(challengeId);
    } catch (error) {
      setSpinning && setSpinning(false);
    }
  };

  const store: ChallengeContextProp = {
    createChallengeStatus: apiStatus,
    templates: templates,
    loadTemplates: loadTemplates,
    addChallenge: addChallenge,
    loadCompanyChallenges: loadCompanyChallenges,
    companyChallenges: companyChallenges,
    loadCompanyChallengeDetails: loadCompanyChallengeDetails,
    selectedChallenge: selectedChallenge,
    deleteChallenge: deleteChallenge,
    updateChallenge: updateChallenge,
    predefinedTemplates: predefinedTemplates,
  };

  return <ChallengeContext.Provider value={store}>{children}</ChallengeContext.Provider>;
};

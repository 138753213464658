import { ChangePasswordProvider } from './context/ChangePasswordContext';
import ChangePassword from './ChangePassword';

const LoginWrapper = () => (
  <ChangePasswordProvider>
    <ChangePassword></ChangePassword>
  </ChangePasswordProvider>
);

export default LoginWrapper;

import { FC, Fragment } from 'react';
import { Space } from 'antd';

import { IAppModalProps } from './AppModal.types';
import { AppModalContainer, HeaderSection } from './AppModal.styles';
import SimpleButton from '../buttons/SimpleButton/SimpleButton';

const AppModal: FC<IAppModalProps> = ({
  visible,
  children,
  titleText,
  header = null,
  handleOk,
  handleCancel,
  showCancelButton = true,
  showButtons = true,
  overrideBackButtonText = undefined,
  overrideBackButtonFunction = undefined,
  overrideSubmitButtonText = undefined,
  className,
  readonlyBtn = false,
  width,
  closable = true,
}: IAppModalProps) => {
  return (
    <AppModalContainer
      title={
        (titleText || header) && (
          <>
            {titleText}
            {header && <HeaderSection>{header}</HeaderSection>}
          </>
        )
      }
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={width || 782}
      closable={closable}
      className={className}
      maskClosable={false}
      footer={
        showButtons && [
          <Fragment key={'AppFooter'}>
            <Space size={'large'}>
              {showCancelButton && (
                <SimpleButton
                  disabled={readonlyBtn}
                  data-testid="modal-cancel"
                  key="back"
                  type="alt"
                  onClick={overrideBackButtonFunction || handleCancel}
                >
                  {overrideBackButtonText || 'cancel'}
                </SimpleButton>
              )}

              <SimpleButton
                disabled={readonlyBtn}
                data-testid="modal-save"
                key="submit"
                type="primary"
                onClick={handleOk}
              >
                {overrideSubmitButtonText || 'save'}
              </SimpleButton>
            </Space>
          </Fragment>,
        ]
      }
    >
      {children}
    </AppModalContainer>
  );
};

export default AppModal;

import styled from '@emotion/styled';
import { Typography } from 'antd';

const { Text } = Typography;

const StyledFormLabel = styled(Text)`
  font-size: 14px;
  line-height: 23.8px;
  font-weight: 400;
  margin-bottom: 4px;
  color: #585e6d;
  width: fit-content;
  display: flex;
`;

const HelperText = styled(`div`)`
  font-size: 13px;
  line-height: 18.2px;
  color: #585e6d;
  width: fit-content;
  display: flex;
  color: #7d8293;
`;

export { StyledFormLabel, HelperText };

import styled from '@emotion/styled';

const StyledTags = styled('div')`
  padding: 20px 0;
  text-align: left;
`;

const StyledError = styled('div')`
  padding: 5px 0;
  text-align: left;
  color: red;
`;

const StyledContainer = styled('div')`
  .rti--input {
    background: #f5f5f5;
  }
`;

export { StyledTags, StyledError, StyledContainer };

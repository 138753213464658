import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { SignupContext } from '../Signup/context/SignupProvider';
import { StyledContainer, StyledContent, StyledHeader, StyledLogo, StyledTitle, StyledSection } from './styles';

const VerifyEmailMessage = () => {
  const { signedEmail } = useContext(SignupContext);

  return (
    <>
      <StyledHeader>
        <StyledLogo className="logo">{'FitClub'}</StyledLogo>
      </StyledHeader>
      <StyledContainer>
        <StyledSection>
          <StyledTitle>Please verify your email</StyledTitle>
          <StyledContent>
            We’ve sent a link to {signedEmail}. Click the link in the email to confirm your email address.
          </StyledContent>
          <StyledContent>
            Didn’t receive a confirmation email? Check your spam filter for an email from admin@fitclub.com or try
            sending again.
          </StyledContent>
          <StyledContent>
            Wrong email? <Link to="/signup">Go back to Sign Up</Link>
          </StyledContent>
        </StyledSection>
      </StyledContainer>
    </>
  );
};

export default VerifyEmailMessage;

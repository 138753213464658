import styled from '@emotion/styled';

const StyledStepsContent = styled('div')`
  padding: 20px 0 10px 0;
`;

const StyledTitle = styled('div')`
  text-align: left;
`;

const StyledDescription = styled('div')`
  margin-top: 8px;
  text-align: left;
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  padding-bottom: 20px;
`;

export { StyledStepsContent, StyledTitle, StyledDescription };

import React, { ReactElement, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginApi, requestChangePasswordApi } from '../../../api/axios-api';
import { ApiStatus } from '../../../common/rootTypes';
import { GlobalContext } from '../../../context/GlobalProvider';

export type LoginRequest = {
  email: string;
  password: string;
  subdomain: string;
  token: string;
};

type LoginContextProp = {
  login: ((signup: LoginRequest) => Promise<void>) | null;
  loginAPIStatus: ApiStatus;
  loginError: string | null;
  requestChangePassword: ((email: string) => Promise<void>) | null;
  requestChangePasswordApiStatus: ApiStatus;
};

export const initialState: LoginContextProp = {
  login: null,
  loginAPIStatus: ApiStatus.idle,
  loginError: null,
  requestChangePassword: null,
  requestChangePasswordApiStatus: ApiStatus.idle,
};

export const LoginContext = React.createContext<LoginContextProp>(initialState);

export const LoginProvider = (props: { children: ReactElement | ReactElement[] }) => {
  const { setSpinning } = useContext(GlobalContext);

  const { children } = props;
  const [apiStatus, setApiStatus] = useState<ApiStatus>(ApiStatus.idle);
  const [requestChangePasswordApiStatus, setRequestChangePasswordApiStatus] = useState<ApiStatus>(ApiStatus.idle);

  const [error, setError] = useState<string | null>(null);

  const navigate = useNavigate();

  const login = async (loginRequest: LoginRequest) => {
    setError(null);
    setApiStatus(ApiStatus.pending);
    setSpinning && setSpinning(true);

    try {
      const response = await loginApi(loginRequest);
      if (response.status == 200) {
        setApiStatus(ApiStatus.succeeded);
        setSpinning && setSpinning(false);

        localStorage.setItem('token', response.data.body.token);
        navigate('/');
      } else {
        //set error code
        setApiStatus(ApiStatus.failed);
        setError('Invalid username or password. Please try again!');
        setSpinning && setSpinning(false);
      }
    } catch (error) {
      setApiStatus(ApiStatus.failed);
      setSpinning && setSpinning(false);

      setError('Invalid username or password. Please try again!');
    }
  };

  const requestChangePassword = async (email: string) => {
    setError(null);
    setRequestChangePasswordApiStatus(ApiStatus.pending);
    setSpinning && setSpinning(true);

    try {
      const response = await requestChangePasswordApi(email);
      if (response.status == 200) {
        setRequestChangePasswordApiStatus(ApiStatus.succeeded);
        setSpinning && setSpinning(false);
      } else {
        //set error code
        setRequestChangePasswordApiStatus(ApiStatus.failed);
        setSpinning && setSpinning(false);
      }
    } catch (error) {
      setRequestChangePasswordApiStatus(ApiStatus.failed);
      setSpinning && setSpinning(false);
    }
  };

  const store: LoginContextProp = {
    login,
    loginAPIStatus: apiStatus,
    loginError: error,
    requestChangePassword: requestChangePassword,
    requestChangePasswordApiStatus: requestChangePasswordApiStatus,
  };

  return <LoginContext.Provider value={store}>{children}</LoginContext.Provider>;
};

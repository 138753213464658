import { Avatar } from 'antd';
import { FC } from 'react';
import { DetailsContainer, ProfileColumnContainer, StyledName, Subtitle } from './styles';

export interface IProfileColumnProps {
  name: string;
  email: string;
  profilePicture: string;
  avatarSize?: 'large' | 'small' | 'default';
}

const ProfileColumn: FC<IProfileColumnProps> = ({
  name,
  email,
  avatarSize = 'default',
  profilePicture,
}: IProfileColumnProps) => {
  return (
    <ProfileColumnContainer>
      <div>
        <Avatar src={`${process.env.REACT_APP_AVATAR_BUCKET}/${profilePicture}`} size={avatarSize}>
          {name}
        </Avatar>
      </div>
      <DetailsContainer>
        <StyledName>{name}</StyledName>
        <Subtitle>
          <span>{email}</span>
        </Subtitle>
      </DetailsContainer>
    </ProfileColumnContainer>
  );
};

export default ProfileColumn;

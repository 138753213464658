import React, { ReactElement, useState } from 'react';

type GlobalContextProp = {
  shouldSpin: boolean;
  setSpinning: ((val: boolean) => void) | null;
};

export const initialState: GlobalContextProp = {
  shouldSpin: false,
  setSpinning: null,
};

export const GlobalContext = React.createContext<GlobalContextProp>(initialState);

export const GlobalProvider = (props: { children: ReactElement | ReactElement[] }) => {
  const { children } = props;
  const [shouldSpin, setShouldSpin] = useState<boolean>(false);

  const store: GlobalContextProp = {
    shouldSpin: shouldSpin,
    setSpinning: setShouldSpin,
  };

  return <GlobalContext.Provider value={store}>{children}</GlobalContext.Provider>;
};

import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Col, Form, Input, Row } from 'antd';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import SimpleButton from '../../../components/buttons/SimpleButton/SimpleButton';
import Dropdown from '../../../components/Forms/Dropdown/Dropdown';
import TextField from '../../../components/Forms/TextField/TextField';
import image from '../../../images/signup.png';
import { SignupContext, SignupRequest } from './context/SignupProvider';
import {
  StyledButtonRow,
  StyledContainer,
  StyledDiv,
  StyledForm,
  StyledFormContainer,
  StyledFormSubtitle,
  StyledFormTitle,
  StyledImage,
  StyledImageContainer,
} from './styles';
import { ApiStatus } from '../../../common/rootTypes';

const gutterh = 16;
const gutterv = 24;
const largeColspan = 12;

const Signup = () => {
  const fullHostname = window.location.hostname;
  const parts = fullHostname.split('.');
  const subdomain = parts.length >= 2 ? parts[0] : '';

  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

  const { signup, initialFormValues, signupStatus } = useContext(SignupContext);

  const [form] = Form.useForm();

  const handleSignup = () => {
    form
      .validateFields()
      .then(() => {
        const {
          firstName,
          lastName,
          email,
          mobile,
          companyName,
          numberOfEmployees,
          industry,
          countryRegion,
          password,
          confirmPassword,
        } = form.getFieldsValue();

        if (password !== confirmPassword) {
          form.setFields([
            {
              name: 'confirmPassword',
              errors: ['Please insert the same Password'],
            },
          ]);
          return;
        }

        const payload: SignupRequest = {
          firstName,
          lastName,
          email,
          mobile,
          companyName,
          numberOfEmployees,
          industry,
          countryRegion,
          password,
          subdomain,
          invitationCode: initialFormValues?.invitationCode,
        };
        signup && signup(payload);
      })
      .catch((err) => {
        // handle errors
      });
  };

  const alterVisibolity = () => setPasswordVisible(!passwordVisible);

  if (signupStatus === ApiStatus.pending) return <div>Loading</div>;

  return (
    <StyledContainer>
      <StyledFormContainer>
        <StyledDiv>Fitclub</StyledDiv>
        <StyledForm
          form={form}
          name={'Test'}
          initialValues={{
            email: initialFormValues?.email,
            mobile: initialFormValues?.contactNumber,
          }}
        >
          <StyledFormTitle>Signup for FitClub</StyledFormTitle>
          <StyledFormSubtitle>
            Create a free account or &nbsp;<Link to={'/login'}>Log in</Link>
          </StyledFormSubtitle>
          <Row gutter={[gutterv, gutterh]}>
            <Col span={largeColspan}>
              <TextField name={'firstName'} label={'First Name'} required={true} placeholder={'John'} />
            </Col>
            <Col span={largeColspan}>
              <TextField name={'lastName'} label={'Last Name'} required={true} placeholder={'Doe'} />
            </Col>
          </Row>
          <Row gutter={[gutterv, gutterh]}>
            <Col span={largeColspan}>
              <TextField
                name={'email'}
                label={'Email Address'}
                required={true}
                placeholder={'johndoe@sephora.com'}
                defaultValue={initialFormValues?.email}
                disabled
              />
            </Col>
            <Col span={largeColspan}>
              <TextField
                name={'mobile'}
                label={'Mobile'}
                required={true}
                placeholder={''}
                defaultValue={initialFormValues?.contactNumber}
                disabled
              />
            </Col>
          </Row>
          <Row gutter={[gutterv, gutterh]}>
            <Col span={largeColspan}>
              <TextField name={'companyName'} label={'Company Name'} required={true} placeholder={''} />
            </Col>
            <Col span={largeColspan}>
              <Dropdown
                name={'numberOfEmployees'}
                label={'Number of Employees'}
                items={[
                  { id: 100, text: '1 - 100' },
                  { id: 500, text: '101 - 1000' },
                  { id: 1000, text: '1000+' },
                ]}
                required={true}
              ></Dropdown>
            </Col>
          </Row>
          <Row gutter={[gutterv, gutterh]}>
            <Col span={largeColspan}>
              <Dropdown
                allowSearch={true}
                name={'industry'}
                label={'Industry'}
                items={[
                  { id: 'Advanced Manufacturing', text: 'Advanced Manufacturing' },
                  { id: 'Aerospace/ Aviation/ SpaceTech', text: 'Aerospace/ Aviation/ SpaceTech' },
                  { id: 'Agriculture and Food', text: 'Agriculture and Food' },
                  { id: 'Autonomous Vehicles / Urban Mobility', text: 'Autonomous Vehicles / Urban Mobility' },
                  { id: 'Consumer Businesses (e.g. FMCG)', text: 'Consumer Businesses (e.g. FMCG)' },
                  { id: 'Cybersecurity', text: 'Cybersecurity' },
                  { id: 'Education', text: 'Education' },
                  { id: 'Electronics', text: 'Electronics' },
                  { id: 'Energy', text: 'Energy' },
                  { id: 'Financial Services', text: 'Financial Services' },
                  { id: 'Government / Public Sector', text: 'Government / Public Sector' },
                  { id: 'Health and Biomedical Sciences', text: 'Health and Biomedical Sciences' },
                  { id: 'Internet and Telecommunications', text: 'Internet and Telecommunications' },
                  { id: 'Logistics and Supply Chain', text: 'Logistics and Supply Chain' },
                  { id: 'Others', text: 'Others' },
                  {
                    id: 'Professional Services (e.g. Consulting, Accounting)',
                    text: 'Professional Services (e.g. Consulting, Accounting)',
                  },
                  { id: 'Urban Solutions and Smart Cities', text: 'Urban Solutions and Smart Cities' },
                  { id: 'Venture Capital/ Private Equity', text: 'Venture Capital/ Private Equity' },
                ]}
                valueType="string"
                required={true}
              ></Dropdown>
            </Col>
            <Col span={largeColspan}>
              <Dropdown
                allowSearch={true}
                name={'countryRegion'}
                label={'Country/ Region'}
                items={[
                  { id: 'Afghanistan', text: 'Afghanistan' },
                  { id: 'Albania', text: 'Albania' },
                  { id: 'Algeria', text: 'Algeria' },
                  { id: 'Andorra', text: 'Andorra' },
                  { id: 'Angola', text: 'Angola' },
                  { id: 'Antigua & Deps', text: 'Antigua & Deps' },
                  { id: 'Argentina', text: 'Argentina' },
                  { id: 'Armenia', text: 'Armenia' },
                  { id: 'Australia', text: 'Australia' },
                  { id: 'Austria', text: 'Austria' },
                  { id: 'Azerbaijan', text: 'Azerbaijan' },
                  { id: 'Bahamas', text: 'Bahamas' },
                  { id: 'Bahrain', text: 'Bahrain' },
                  { id: 'Bangladesh', text: 'Bangladesh' },
                  { id: 'Barbados', text: 'Barbados' },
                  { id: 'Belarus', text: 'Belarus' },
                  { id: 'Belgium', text: 'Belgium' },
                  { id: 'Belize', text: 'Belize' },
                  { id: 'Benin', text: 'Benin' },
                  { id: 'Bhutan', text: 'Bhutan' },
                  { id: 'Bolivia', text: 'Bolivia' },
                  { id: 'Bosnia Herzegovina', text: 'Bosnia Herzegovina' },
                  { id: 'Botswana', text: 'Botswana' },
                  { id: 'Brazil', text: 'Brazil' },
                  { id: 'Brunei', text: 'Brunei' },
                  { id: 'Bulgaria', text: 'Bulgaria' },
                  { id: 'Burkina', text: 'Burkina' },
                  { id: 'Burundi', text: 'Burundi' },
                  { id: 'Cambodia', text: 'Cambodia' },
                  { id: 'Cameroon', text: 'Cameroon' },
                  { id: 'Canada', text: 'Canada' },
                  { id: 'Cape Verde', text: 'Cape Verde' },
                  { id: 'Central African Rep', text: 'Central African Rep' },
                  { id: 'Chad', text: 'Chad' },
                  { id: 'Chile', text: 'Chile' },
                  { id: 'China', text: 'China' },
                  { id: 'Colombia', text: 'Colombia' },
                  { id: 'Comoros', text: 'Comoros' },
                  { id: 'Congo', text: 'Congo' },
                  { id: 'Costa Rica', text: 'Costa Rica' },
                  { id: 'Croatia', text: 'Croatia' },
                  { id: 'Cuba', text: 'Cuba' },
                  { id: 'Cyprus', text: 'Cyprus' },
                  { id: 'Czech Republic', text: 'Czech Republic' },
                  { id: 'Denmark', text: 'Denmark' },
                  { id: 'Djibouti', text: 'Djibouti' },
                  { id: 'Dominica', text: 'Dominica' },
                  { id: 'Dominican Republic', text: 'Dominican Republic' },
                  { id: 'East Timor', text: 'East Timor' },
                  { id: 'Ecuador', text: 'Ecuador' },
                  { id: 'Egypt', text: 'Egypt' },
                  { id: 'El Salvador', text: 'El Salvador' },
                  { id: 'Equatorial Guinea', text: 'Equatorial Guinea' },
                  { id: 'Eritrea', text: 'Eritrea' },
                  { id: 'Estonia', text: 'Estonia' },
                  { id: 'Ethiopia', text: 'Ethiopia' },
                  { id: 'Fiji', text: 'Fiji' },
                  { id: 'Finland', text: 'Finland' },
                  { id: 'France', text: 'France' },
                  { id: 'Gabon', text: 'Gabon' },
                  { id: 'Gambia', text: 'Gambia' },
                  { id: 'Georgia', text: 'Georgia' },
                  { id: 'Germany', text: 'Germany' },
                  { id: 'Ghana', text: 'Ghana' },
                  { id: 'Greece', text: 'Greece' },
                  { id: 'Grenada', text: 'Grenada' },
                  { id: 'Guatemala', text: 'Guatemala' },
                  { id: 'Guinea', text: 'Guinea' },
                  { id: 'Guinea-Bissau', text: 'Guinea-Bissau' },
                  { id: 'Guyana', text: 'Guyana' },
                  { id: 'Haiti', text: 'Haiti' },
                  { id: 'Honduras', text: 'Honduras' },
                  { id: 'Hungary', text: 'Hungary' },
                  { id: 'Iceland', text: 'Iceland' },
                  { id: 'India', text: 'India' },
                  { id: 'Indonesia', text: 'Indonesia' },
                  { id: 'Iran', text: 'Iran' },
                  { id: 'Iraq', text: 'Iraq' },
                  { id: 'Ireland {Republic}', text: 'Ireland {Republic}' },
                  { id: 'Israel', text: 'Israel' },
                  { id: 'Italy', text: 'Italy' },
                  { id: 'Ivory Coast', text: 'Ivory Coast' },
                  { id: 'Jamaica', text: 'Jamaica' },
                  { id: 'Japan', text: 'Japan' },
                  { id: 'Jordan', text: 'Jordan' },
                  { id: 'Kazakhstan', text: 'Kazakhstan' },
                  { id: 'Kenya', text: 'Kenya' },
                  { id: 'Kiribati', text: 'Kiribati' },
                  { id: 'Korea North', text: 'Korea North' },
                  { id: 'Korea South', text: 'Korea South' },
                  { id: 'Kosovo', text: 'Kosovo' },
                  { id: 'Kuwait', text: 'Kuwait' },
                  { id: 'Kyrgyzstan', text: 'Kyrgyzstan' },
                  { id: 'Laos', text: 'Laos' },
                  { id: 'Latvia', text: 'Latvia' },
                  { id: 'Lebanon', text: 'Lebanon' },
                  { id: 'Lesotho', text: 'Lesotho' },
                  { id: 'Liberia', text: 'Liberia' },
                  { id: 'Libya', text: 'Libya' },
                  { id: 'Liechtenstein', text: 'Liechtenstein' },
                  { id: 'Lithuania', text: 'Lithuania' },
                  { id: 'Luxembourg', text: 'Luxembourg' },
                  { id: 'Macedonia', text: 'Macedonia' },
                  { id: 'Madagascar', text: 'Madagascar' },
                  { id: 'Malawi', text: 'Malawi' },
                  { id: 'Malaysia', text: 'Malaysia' },
                  { id: 'Maldives', text: 'Maldives' },
                  { id: 'Mali', text: 'Mali' },
                  { id: 'Malta', text: 'Malta' },
                  { id: 'Marshall Islands', text: 'Marshall Islands' },
                  { id: 'Mauritania', text: 'Mauritania' },
                  { id: 'Mauritius', text: 'Mauritius' },
                  { id: 'Mexico', text: 'Mexico' },
                  { id: 'Micronesia', text: 'Micronesia' },
                  { id: 'Moldova', text: 'Moldova' },
                  { id: 'Monaco', text: 'Monaco' },
                  { id: 'Mongolia', text: 'Mongolia' },
                  { id: 'Montenegro', text: 'Montenegro' },
                  { id: 'Morocco', text: 'Morocco' },
                  { id: 'Mozambique', text: 'Mozambique' },
                  { id: 'Myanmar, {Burma}', text: 'Myanmar, {Burma}' },
                  { id: 'Namibia', text: 'Namibia' },
                  { id: 'Nauru', text: 'Nauru' },
                  { id: 'Nepal', text: 'Nepal' },
                  { id: 'Netherlands', text: 'Netherlands' },
                  { id: 'New Zealand', text: 'New Zealand' },
                  { id: 'Nicaragua', text: 'Nicaragua' },
                  { id: 'Niger', text: 'Niger' },
                  { id: 'Nigeria', text: 'Nigeria' },
                  { id: 'Norway', text: 'Norway' },
                  { id: 'Oman', text: 'Oman' },
                  { id: 'Pakistan', text: 'Pakistan' },
                  { id: 'Palau', text: 'Palau' },
                  { id: 'Panama', text: 'Panama' },
                  { id: 'Papua New Guinea', text: 'Papua New Guinea' },
                  { id: 'Paraguay', text: 'Paraguay' },
                  { id: 'Peru', text: 'Peru' },
                  { id: 'Philippines', text: 'Philippines' },
                  { id: 'Poland', text: 'Poland' },
                  { id: 'Portugal', text: 'Portugal' },
                  { id: 'Qatar', text: 'Qatar' },
                  { id: 'Romania', text: 'Romania' },
                  { id: 'Russian Federation', text: 'Russian Federation' },
                  { id: 'Rwanda', text: 'Rwanda' },
                  { id: 'St Kitts & Nevis', text: 'St Kitts & Nevis' },
                  { id: 'St Lucia', text: 'St Lucia' },
                  { id: 'Saint Vincent & the Grenadines', text: 'Saint Vincent & the Grenadines' },
                  { id: 'Samoa', text: 'Samoa' },
                  { id: 'San Marino', text: 'San Marino' },
                  { id: 'Sao Tome & Principe', text: 'Sao Tome & Principe' },
                  { id: 'Saudi Arabia', text: 'Saudi Arabia' },
                  { id: 'Senegal', text: 'Senegal' },
                  { id: 'Serbia', text: 'Serbia' },
                  { id: 'Seychelles', text: 'Seychelles' },
                  { id: 'Sierra Leone', text: 'Sierra Leone' },
                  { id: 'Singapore', text: 'Singapore' },
                  { id: 'Slovakia', text: 'Slovakia' },
                  { id: 'Slovenia', text: 'Slovenia' },
                  { id: 'Solomon Islands', text: 'Solomon Islands' },
                  { id: 'Somalia', text: 'Somalia' },
                  { id: 'South Africa', text: 'South Africa' },
                  { id: 'South Sudan', text: 'South Sudan' },
                  { id: 'Spain', text: 'Spain' },
                  { id: 'Sri Lanka', text: 'Sri Lanka' },
                  { id: 'Sudan', text: 'Sudan' },
                  { id: 'Suriname', text: 'Suriname' },
                  { id: 'Swaziland', text: 'Swaziland' },
                  { id: 'Sweden', text: 'Sweden' },
                  { id: 'Switzerland', text: 'Switzerland' },
                  { id: 'Syria', text: 'Syria' },
                  { id: 'Taiwan', text: 'Taiwan' },
                  { id: 'Tajikistan', text: 'Tajikistan' },
                  { id: 'Tanzania', text: 'Tanzania' },
                  { id: 'Thailand', text: 'Thailand' },
                  { id: 'Togo', text: 'Togo' },
                  { id: 'Tonga', text: 'Tonga' },
                  { id: 'Trinidad & Tobago', text: 'Trinidad & Tobago' },
                  { id: 'Tunisia', text: 'Tunisia' },
                  { id: 'Turkey', text: 'Turkey' },
                  { id: 'Turkmenistan', text: 'Turkmenistan' },
                  { id: 'Tuvalu', text: 'Tuvalu' },
                  { id: 'Uganda', text: 'Uganda' },
                  { id: 'Ukraine', text: 'Ukraine' },
                  { id: 'United Arab Emirates', text: 'United Arab Emirates' },
                  { id: 'United Kingdom', text: 'United Kingdom' },
                  { id: 'United States', text: 'United States' },
                  { id: 'Uruguay', text: 'Uruguay' },
                  { id: 'Uzbekistan', text: 'Uzbekistan' },
                  { id: 'Vanuatu', text: 'Vanuatu' },
                  { id: 'Vatican City', text: 'Vatican City' },
                  { id: 'Venezuela', text: 'Venezuela' },
                  { id: 'Vietnam', text: 'Vietnam' },
                  { id: 'Yemen', text: 'Yemen' },
                  { id: 'Zambia', text: 'Zambia' },
                  { id: 'Zimbabwe', text: 'Zimbabwe' },
                ]}
                valueType="string"
                required={true}
              ></Dropdown>
            </Col>
          </Row>
          <Row gutter={[gutterv, gutterh]}>
            <Col span={largeColspan}>
              <TextField
                name={'password'}
                label={'Password'}
                type={passwordVisible ? 'text' : 'password'}
                required={true}
                suffix={
                  passwordVisible ? (
                    <EyeTwoTone onClick={alterVisibolity} />
                  ) : (
                    <EyeInvisibleOutlined onClick={alterVisibolity} />
                  )
                }
                pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
                patternValMsg="Please enter a correct password"
                tooltip="Please enter minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character"
                placeholder="Enter password"
              />
            </Col>
            <Col span={largeColspan}>
              <TextField
                name={'confirmPassword'}
                label={'Confirm Password'}
                type={passwordVisible ? 'text' : 'password'}
                required={true}
                suffix={
                  passwordVisible ? (
                    <EyeTwoTone onClick={alterVisibolity} />
                  ) : (
                    <EyeInvisibleOutlined onClick={alterVisibolity} />
                  )
                }
                pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
                patternValMsg="Please enter a correct password"
                tooltip="Please enter minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character"
                placeholder="Retype your password"
              />
            </Col>
          </Row>
          <StyledFormSubtitle>
            By creating an account, you agree to our{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://www.fitclub.work/terms.pdf">
              &nbsp;Terms&nbsp;
            </a>{' '}
            and have read and acknowledge the{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://www.fitclub.work/privacy.pdf">
              Global Privacy Statement
            </a>
            .
          </StyledFormSubtitle>
          <StyledButtonRow gutter={[gutterv, gutterh]}>
            <SimpleButton type="primary" onClick={handleSignup} className="primaryBtn">
              Sign Up
            </SimpleButton>
          </StyledButtonRow>
        </StyledForm>
      </StyledFormContainer>

      <StyledImageContainer>
        <StyledImage src={image} />
      </StyledImageContainer>
    </StyledContainer>
  );
};

export default Signup;

import { Col, Divider, Form, Layout, Menu, MenuProps, Row, Table } from 'antd';
import { FileTextOutlined } from '@ant-design/icons';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import SimpleButton from '../../components/buttons/SimpleButton/SimpleButton';
import TextArea from '../../components/Forms/TextArea/TextArea';
import TextField from '../../components/Forms/TextField/TextField';
import AppModal from '../../components/Modal/AppModal';
import Product, { InvitedProductResponse } from '../Onboard/Product';
import CardDetails from './CardDetails';
import { ChangePasswordRequest, CustomerContext, UserDetails } from './context/CustomerProvider';
import {
  StyledContainer,
  StyledContent,
  StyledDetailsContent,
  StyledInfoDetails,
  StyledInfoTitle,
  StyledProductName,
  StyledTableHeader,
  StyledLinkText,
  StyledNoMembers,
} from './styles';
import EditMembers from './EditMembers';
import { dateFormat } from '../../common/utils';
import EditAdmins from './EditAdmins';
import { Link, useNavigate } from 'react-router-dom';
import { DeleteOutlined } from '@mui/icons-material';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { getInvitedProductDetailsApi } from '../../api/axios-api';

const { Sider, Content } = Layout;

const gutterh = 16;
const gutterv = 24;

const Settings = () => {
  const [form] = Form.useForm();
  const [changePasswordForm] = Form.useForm();
  const [changePlanForm] = Form.useForm();
  const navigate = useNavigate();

  const decodedToken: { email: string } = jwt_decode(localStorage.getItem('token') as string);

  const {
    selectedCustomer,
    loadCustomerDetails,
    unsubscribe,
    changePassword,
    changeProduct,
    updateAdmins,
    changeCard,
    updateMembers,
    removeUser,
    requestEnterprise,
  } = useContext(CustomerContext);

  const [isCancelModalVisible, setCancelModalVisible] = useState<boolean>(false);
  const [isEditMembersModalVisible, setEditMembersModalVisible] = useState<boolean>(false);
  const [isChangePlanModalVisible, setChangePlanModalVisible] = useState<boolean>(false);
  const [isEditAdminModalVisible, setEditAdminModalVisible] = useState<boolean>(false);
  const [isUpdateCardModalVisible, setUpdateCardModalVisible] = useState<boolean>(false);
  const [isDeleteConfirmationModalVisible, setDeleteConfirmationModalVisible] = useState<boolean>(false);
  const [selectedRemovingUser, setSelectedRemovingUser] = useState<string | null>(null);
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [invitedProduct, setInvitedProduct] = useState<InvitedProductResponse | null>(null);

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string);

  useEffect(() => {
    loadCustomerDetails && loadCustomerDetails();

    const fetchInivitedProduct = async () => {
      const response = await getInvitedProductDetailsApi();

      if (response.status == 200) {
        setInvitedProduct(response.data.body);
      }
    };

    fetchInivitedProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleClick(event: React.MouseEvent<HTMLElement>, val: string) {
    event.preventDefault();
    document.querySelector(`[id="${val}"]`)?.scrollIntoView({ behavior: 'smooth' });
  }

  const handleDelete = (id: string) => {
    setSelectedRemovingUser(id);
    setDeleteConfirmationModalVisible(true);
  };

  const sideMenuItems: MenuProps['items'] = [
    {
      key: 1,
      label: (
        <a href={''} onClick={(e) => handleClick(e, 'paymentPlan')}>
          Payment plan
        </a>
      ),
    },
    {
      key: 2,
      label: (
        <a href={''} onClick={(e) => handleClick(e, 'billing')}>
          Billing
        </a>
      ),
    },
    {
      key: 3,
      label: (
        <a href={''} onClick={(e) => handleClick(e, 'adminDetails')}>
          Admin details
        </a>
      ),
    },
    {
      key: 4,
      label: (
        <a href={''} onClick={(e) => handleClick(e, 'teamMembers')}>
          Team members
        </a>
      ),
    },
    {
      key: 5,
      label: (
        <a href={''} onClick={(e) => handleClick(e, 'changePassword')}>
          Change Password
        </a>
      ),
    },
  ];

  const columns = [
    {
      title: '#',
      key: 'rank',
      render: (record: UserDetails) => {
        return selectedCustomer && selectedCustomer?.users?.indexOf(record) + 1;
      },
    },
    {
      title: 'Name',
      key: 'name',
      render: (record: UserDetails) => <>{`${record.firstName} ${record.lastName}`}</>,
    },
    {
      title: 'Email Address',
      key: 'email',
      render: (record: UserDetails) => <>{record.email}</>,
    },
    {
      title: '',
      key: 'action',
      width: '90',
      render: (record: UserDetails) => (
        <div onClick={() => handleDelete(record.id)}>
          <DeleteOutlined />
        </div>
      ),
    },
  ];

  const productDetails: { [key: string]: any } = {
    [process.env.REACT_APP_PRODUCT_ESSENTIAL as string]: {
      name: 'Essential',
      options: [
        'Unlimited users',
        'Pre-designed challenge templates',
        'Monthly gifts for the top performers',
        '7 - days free trial',
        'Mobile app for the users',
        'Unlimited access to create custom challenges',
      ],
      price: 50,
    },
    [process.env.REACT_APP_PRODUCT_STANDARD as string]: {
      name: 'Standard',
      options: [
        'unlimited templates of fitness challenges',
        'up to 150 users',
        'create custom fitness challenges',
        '3 monthly gifts with company branding for the top 3 winners',
      ],
      price: 299,
    },
  };

  const handleUnsubscription = () => {
    form.validateFields().then(() => {
      unsubscribe && unsubscribe(form.getFieldValue('reason'));
    });
  };

  const handleChangePlan = () => {
    const product = changePlanForm.getFieldValue('product');
    if (product) {
      if (product === '-1') {
        requestEnterprise && requestEnterprise();
        navigate('/getback');
      } else {
        changePlanForm.validateFields().then(() => {
          changeProduct && changeProduct(product);
        });
      }
    }

    setChangePlanModalVisible(false);
  };

  const isFreeTrailActive =
    selectedCustomer &&
    selectedCustomer.subscription.trialEndDate &&
    moment(selectedCustomer.subscription.trialEndDate).isAfter();

  const handleChangePassword = () => {
    changePasswordForm
      .validateFields()
      .then(() => {
        const { oldPassword, newPassword, confirmPassword } = changePasswordForm.getFieldsValue();

        if (newPassword !== confirmPassword) {
          changePasswordForm.setFields([
            {
              name: 'confirmPassword',
              errors: ['Please insert the same Password'],
            },
          ]);
          return;
        }

        const payload: ChangePasswordRequest = {
          currentPassword: oldPassword,
          newPassword: newPassword,
        };
        changePassword && changePassword(payload);
      })
      .catch((err) => {
        // handle errors
      });
  };

  const alterVisibolity = () => setPasswordVisible(!passwordVisible);

  // changePlanForm.setFieldsValue({ product: selectedCustomer?.productId || '' });

  return (
    <>
      <StyledContainer>
        <Layout>
          <Sider width={200} className="site-layout-background">
            <Menu
              mode="inline"
              defaultSelectedKeys={['1']}
              defaultOpenKeys={['sub1']}
              //   style={{ height: '100%', borderRight: 0 }}
              items={sideMenuItems}
            />
          </Sider>

          <StyledContent>
            <Row className="contentTitle" id="paymentPlan">
              Payment plan
            </Row>
            <StyledDetailsContent>
              <Row>
                <Col span={14}>
                  {selectedCustomer && (
                    <>
                      <StyledInfoTitle>Your plan:</StyledInfoTitle>
                      <StyledInfoDetails>
                        <StyledProductName>{`Essential `} </StyledProductName>
                        {/* <div>
                          {isFreeTrailActive
                            ? `Your free trial ends on ${moment(selectedCustomer.subscription.trialEndDate).format(
                                'DD MMM YYYY'
                              )}`
                            : 'Your free trial has ended'}
                        </div> */}
                      </StyledInfoDetails>

                      {/* <StyledInfoDetails>
                        You have,
                        {productDetails[selectedCustomer.productId].options.map((option: string, i: number) => (
                          <div key={i}>{option}</div>
                        ))}
                      </StyledInfoDetails> */}
                    </>
                  )}
                </Col>
                <Col span={6}>
                  <SimpleButton onClick={() => setCancelModalVisible(true)} type="alt-borderless">
                    Cancel Subscription
                  </SimpleButton>
                </Col>
                {/* <Col span={4}>
                  <SimpleButton onClick={() => setChangePlanModalVisible(true)}>Change plan</SimpleButton>
                </Col> */}
              </Row>
            </StyledDetailsContent>
            <Row className="contentTitle" id="billing">
              Billing
            </Row>
            <StyledDetailsContent>
              <Row>
                <Col span={24}>
                  {selectedCustomer && (
                    <>
                      <StyledInfoTitle>Billing cycle</StyledInfoTitle>
                      <StyledInfoDetails>
                        <div>${(invitedProduct?.pricePerUser || 0) * (invitedProduct?.users || 0)} USD / month</div>
                        {/* <div>
                          {isFreeTrailActive
                            ? `You will be charged when your trial ends on  ${moment(
                                selectedCustomer.subscription.trialEndDate
                              ).format('DD MMM YYYY')}`
                            : 'Your free trial has ended'}
                        </div> */}
                      </StyledInfoDetails>

                      <StyledInfoTitle>Billing information</StyledInfoTitle>
                      <StyledInfoDetails>
                        <div>Credit card number:</div>
                        <Row>
                          <Col span={20}>
                            <div>Visa ending in {selectedCustomer.card.last4}</div>
                          </Col>
                          <Col span={4}>
                            <SimpleButton onClick={() => setUpdateCardModalVisible(true)}>Update</SimpleButton>
                          </Col>
                        </Row>
                        <div>{`Expires ${selectedCustomer.card.expMonth}/ ${selectedCustomer.card.expYear}`}</div>
                      </StyledInfoDetails>

                      <StyledInfoTitle>Invoices</StyledInfoTitle>
                      {selectedCustomer.subscription.invoices && selectedCustomer.subscription.invoices.length > 0
                        ? selectedCustomer.subscription.invoices.map((i, index) => (
                            <>
                              <Row key={index}>
                                <Col span={16}>{dateFormat(i.date)}</Col>
                                <Col span={4}>{`$ ${i.amount} USD`}</Col>
                                <Col>
                                  <a href={i.invoicePdf}>
                                    <FileTextOutlined />
                                    <StyledLinkText>Receipt</StyledLinkText>
                                  </a>
                                </Col>
                              </Row>
                              {index !== selectedCustomer.subscription.invoices.length - 1 && <Divider />}
                            </>
                          ))
                        : 'No invoices'}
                    </>
                  )}
                </Col>
              </Row>
            </StyledDetailsContent>
            <Row className="contentTitle" id="adminDetails">
              Admin details
            </Row>

            <StyledDetailsContent>
              <Row gutter={[gutterh, gutterv]}>
                <Col span={20}>
                  <StyledInfoTitle>{selectedCustomer?.admins.length || 'No'} more admins on your team.</StyledInfoTitle>
                </Col>
                <Col span={4}>
                  <SimpleButton onClick={() => setEditAdminModalVisible(true)}>Edit Admins</SimpleButton>
                </Col>
              </Row>
              {selectedCustomer?.admins.map((admin, i) => (
                <StyledInfoDetails key={i}>{admin.email}</StyledInfoDetails>
              ))}
            </StyledDetailsContent>

            <Row className="contentTitle" id="teamMembers">
              Team members
            </Row>
            <StyledDetailsContent>
              <StyledTableHeader>
                <SimpleButton onClick={() => setEditMembersModalVisible(true)}>Edit List</SimpleButton>
              </StyledTableHeader>

              {selectedCustomer && selectedCustomer.users.length > 0 ? (
                <Table dataSource={selectedCustomer.users} columns={columns} />
              ) : (
                <StyledNoMembers>No team members yet.</StyledNoMembers>
              )}
            </StyledDetailsContent>

            <Row className="contentTitle" id="changePassword">
              Change password
            </Row>
            <StyledDetailsContent>
              <div>Enter a new password for {decodedToken?.email}</div>
              <div>Make sure to include at least:</div>
              <ul>
                <li>8 characters</li>
                <li>1 letter</li>
                <li>1 number</li>
              </ul>
              <Form form={changePasswordForm}>
                <Row>
                  <Col span={8}>
                    <TextField name={'oldPassword'} label={'Current Password'} type={'password'} required={true} />
                    <TextField
                      name={'newPassword'}
                      label={'New Password'}
                      type={passwordVisible ? 'text' : 'password'}
                      required={true}
                      suffix={
                        passwordVisible ? (
                          <EyeTwoTone onClick={alterVisibolity} />
                        ) : (
                          <EyeInvisibleOutlined onClick={alterVisibolity} />
                        )
                      }
                      pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
                      patternValMsg="Please enter a correct password"
                      tooltip="Please enter minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character"
                    />

                    <TextField
                      name={'confirmPassword'}
                      label={'Confirm New Password'}
                      type={passwordVisible ? 'text' : 'password'}
                      required={true}
                      suffix={
                        passwordVisible ? (
                          <EyeTwoTone onClick={alterVisibolity} />
                        ) : (
                          <EyeInvisibleOutlined onClick={alterVisibolity} />
                        )
                      }
                      pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
                      patternValMsg="Please enter a correct password"
                      tooltip="Please enter minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character"
                    />
                  </Col>
                </Row>
                <Row>
                  <SimpleButton type="primary" onClick={handleChangePassword} className="primaryBtn">
                    Update
                  </SimpleButton>
                </Row>
              </Form>
            </StyledDetailsContent>
          </StyledContent>
        </Layout>
      </StyledContainer>
      <AppModal
        visible={isCancelModalVisible}
        handleCancel={() => setCancelModalVisible(false)}
        width={600}
        titleText={'Cancel Subscription'}
        handleOk={() => handleUnsubscription()}
      >
        <Form form={form}>
          <TextArea name={'reason'} label={'Reason for cancelling subscription'} rows={0} required={true} />
        </Form>
      </AppModal>
      <AppModal
        visible={isChangePlanModalVisible}
        handleCancel={() => setChangePlanModalVisible(false)}
        width={600}
        titleText={'Choose a plan'}
        handleOk={() => handleChangePlan()}
      >
        <Product form={changePlanForm} />
      </AppModal>
      {isEditMembersModalVisible && (
        <EditMembers
          handleCancel={() => setEditMembersModalVisible(false)}
          handleOk={(members: string[]) => {
            updateMembers && updateMembers(members);
            setEditMembersModalVisible(false);
          }}
        />
      )}
      {isEditAdminModalVisible && (
        <EditAdmins
          adminList={selectedCustomer ? selectedCustomer?.admins : []}
          handleCancel={() => setEditAdminModalVisible(false)}
          handleSubmit={(admins: string[]) => {
            updateAdmins && updateAdmins(admins);
            setEditAdminModalVisible(false);
          }}
        />
      )}
      <Elements stripe={stripePromise}>
        <CardDetails
          isModalVisible={isUpdateCardModalVisible}
          handleCancel={() => setUpdateCardModalVisible(false)}
          handleOk={(tokenId: string) => {
            changeCard && changeCard(tokenId);
            setUpdateCardModalVisible(false);
          }}
        />
      </Elements>
      {isDeleteConfirmationModalVisible && (
        <AppModal
          visible={isDeleteConfirmationModalVisible}
          handleCancel={() => setDeleteConfirmationModalVisible(false)}
          width={600}
          titleText={'Remove User'}
          handleOk={() => {
            selectedRemovingUser && removeUser && removeUser(selectedRemovingUser);
            setDeleteConfirmationModalVisible(false);
          }}
          overrideSubmitButtonText="Remove"
        >
          Are you sure you want to remove this user?
        </AppModal>
      )}
    </>
  );
};

export default Settings;

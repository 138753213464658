/* eslint-disable prefer-const */
import React, { ReactElement, useContext, useState } from 'react';
import {
  changeCardApi,
  changePasswordApi,
  changeProductApi,
  loadCustomerDetailsApi,
  removeUserApi,
  requestEnterpriseProductApi,
  unsubscribeCustomerApi,
  updateAdminsApi,
  updateMembersApi,
} from '../../../api/axios-api';
import { ApiStatus } from '../../../common/rootTypes';
import { GlobalContext } from '../../../context/GlobalProvider';

export type Customer = {
  productId: string;
  card: Card;
  admins: AdminDetails[];
  users: UserDetails[];
  subscription: Subscription;
};

export type Card = {
  brand: string;
  country: string;
  cvcCheck: string;
  expMonth: number;
  expYear: number;
  last4: string;
};

export type AdminDetails = {
  name: string;
  email: string;
};

export type UserDetails = {
  firstName: string;
  lastName: string;
  email: string;
  id: string;
};

export type Invoice = {
  date: string;
  amount: string;
  invoicePdf: string;
};

export type Subscription = {
  trialEndDate: string;
  invoices: Invoice[];
  clientSecret: string;
};

export type ChangePasswordRequest = {
  currentPassword: string;
  newPassword: string;
};

type CustomerContextProp = {
  customerLoadStatus: ApiStatus;
  loadCustomerDetails: (() => Promise<void>) | null;
  selectedCustomer: Customer | null;
  unsubscribe: ((reason: string) => Promise<void>) | null;
  changePassword: ((data: ChangePasswordRequest) => Promise<void>) | null;
  changeProduct: ((productId: string) => Promise<void>) | null;
  updateAdmins: ((admins: string[]) => Promise<void>) | null;
  changeCard: ((tokenId: string) => Promise<void>) | null;
  updateMembers: ((members: string[]) => Promise<void>) | null;
  removeUser: ((userId: string) => Promise<void>) | null;
  requestEnterprise: (() => Promise<void>) | null;
};

export const initialState: CustomerContextProp = {
  customerLoadStatus: ApiStatus.idle,
  loadCustomerDetails: null,
  selectedCustomer: null,
  unsubscribe: null,
  changePassword: null,
  changeProduct: null,
  updateAdmins: null,
  changeCard: null,
  updateMembers: null,
  removeUser: null,
  requestEnterprise: null,
};

export const CustomerContext = React.createContext<CustomerContextProp>(initialState);

export const CustomerProvider = (props: { children: ReactElement | ReactElement[] }) => {
  const { setSpinning } = useContext(GlobalContext);

  const { children } = props;
  const [apiStatus, setApiStatus] = useState<ApiStatus>(ApiStatus.idle);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);

  const loadCustomerDetails = async () => {
    setSpinning && setSpinning(true);

    setApiStatus(ApiStatus.pending);

    try {
      let response = await loadCustomerDetailsApi();
      if (response.status == 200) {
        setSelectedCustomer(response.data.body);
        setApiStatus(ApiStatus.succeeded);
      } else {
        //set error code
        setApiStatus(ApiStatus.failed);
      }
      setSpinning && setSpinning(false);
    } catch (error) {
      setSpinning && setSpinning(false);
    }
  };

  const unsubscribe = async (reason: string) => {
    setSpinning && setSpinning(true);

    setApiStatus(ApiStatus.pending);

    try {
      let response = await unsubscribeCustomerApi(reason);
      if (response.status == 200) {
        setApiStatus(ApiStatus.succeeded);
      } else {
        //set error code
        setApiStatus(ApiStatus.failed);
      }
      setSpinning && setSpinning(false);
    } catch (error) {
      setSpinning && setSpinning(false);
    }
  };

  const changePassword = async (data: ChangePasswordRequest) => {
    setSpinning && setSpinning(true);

    setApiStatus(ApiStatus.pending);

    try {
      let response = await changePasswordApi(data);
      if (response.status == 200) {
        setApiStatus(ApiStatus.succeeded);
      } else {
        //set error code
        setApiStatus(ApiStatus.failed);
      }
      setSpinning && setSpinning(false);
    } catch (error) {
      setSpinning && setSpinning(false);
    }
  };

  const changeProduct = async (productId: string) => {
    setSpinning && setSpinning(true);

    setApiStatus(ApiStatus.pending);

    try {
      let response = await changeProductApi(productId);
      if (response.status == 200) {
        setApiStatus(ApiStatus.succeeded);
      } else {
        //set error code
        setApiStatus(ApiStatus.failed);
      }
      setSpinning && setSpinning(false);
    } catch (error) {
      setSpinning && setSpinning(false);
    }
  };

  const updateAdmins = async (admins: string[]) => {
    setSpinning && setSpinning(true);

    setApiStatus(ApiStatus.pending);

    try {
      let response = await updateAdminsApi(admins);
      if (response.status == 200) {
        setApiStatus(ApiStatus.succeeded);
      } else {
        //set error code
        setApiStatus(ApiStatus.failed);
      }
      setSpinning && setSpinning(false);
    } catch (error) {
      setSpinning && setSpinning(false);
    }
  };

  const changeCard = async (tokenId: string) => {
    setSpinning && setSpinning(true);

    setApiStatus(ApiStatus.pending);

    try {
      let response = await changeCardApi(tokenId);
      if (response.status == 200) {
        setApiStatus(ApiStatus.succeeded);
      } else {
        //set error code
        setApiStatus(ApiStatus.failed);
      }
      setSpinning && setSpinning(false);
    } catch (error) {
      setSpinning && setSpinning(false);
    }
  };

  const updateMembers = async (members: string[]) => {
    setSpinning && setSpinning(true);

    setApiStatus(ApiStatus.pending);

    try {
      let response = await updateMembersApi(members);
      if (response.status == 200) {
        setApiStatus(ApiStatus.succeeded);
      } else {
        //set error code
        setApiStatus(ApiStatus.failed);
      }
      setSpinning && setSpinning(false);
    } catch (error) {
      setSpinning && setSpinning(false);
    }
  };

  const removeUser = async (userId: string) => {
    setSpinning && setSpinning(true);

    setApiStatus(ApiStatus.pending);

    try {
      let response = await removeUserApi(userId);
      if (response.status == 200) {
        setApiStatus(ApiStatus.succeeded);
        loadCustomerDetails();
      } else {
        //set error code
        setApiStatus(ApiStatus.failed);
      }
      setSpinning && setSpinning(false);
    } catch (error) {
      setSpinning && setSpinning(false);
    }
  };

  const requestEnterprise = async () => {
    setSpinning && setSpinning(true);

    setApiStatus(ApiStatus.pending);

    try {
      let response = await requestEnterpriseProductApi();
      if (response.status == 200) {
        setApiStatus(ApiStatus.succeeded);
      } else {
        //set error code
        setApiStatus(ApiStatus.failed);
      }
      setSpinning && setSpinning(false);
    } catch (error) {
      setSpinning && setSpinning(false);
    }
  };

  const store: CustomerContextProp = {
    customerLoadStatus: apiStatus,
    loadCustomerDetails: loadCustomerDetails,
    selectedCustomer: selectedCustomer,
    unsubscribe: unsubscribe,
    changePassword: changePassword,
    changeProduct: changeProduct,
    updateAdmins: updateAdmins,
    changeCard: changeCard,
    updateMembers: updateMembers,
    removeUser: removeUser,
    requestEnterprise: requestEnterprise,
  };

  return <CustomerContext.Provider value={store}>{children}</CustomerContext.Provider>;
};

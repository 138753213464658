import React, { useCallback, useMemo } from 'react';
import { Form, Input } from 'antd';
import { Rule } from 'antd/lib/form';
import { TextAreaProps } from './TextArea.types';
import { TextAreaContainer } from './TextArea.styles';
import FormLabel from '../FormLabel/FormLabel';
const { TextArea } = Input;

const CustomTextArea = ({
  name,
  label,
  placeholder,
  rows,
  width = '100%',
  maxLength = 100,
  required = false,
  requiredValMsg = 'Required',
  requiredMark = false,
  defaultValue,
  disabled = false,
}: TextAreaProps) => {
  // create validation rules
  const createRules = useCallback(() => {
    const rules: Rule[] = [];
    let message: string | undefined = undefined;
    if (required) {
      message = requiredValMsg;
      rules.push({
        required,
        message,
        whitespace: true,
      });
    }
    return rules;
  }, [required, requiredValMsg]);

  const memoizedCreateRules = useMemo(() => createRules(), [createRules]);
  const form = Form.useFormInstance();

  return (
    <TextAreaContainer width={width}>
      <>
        {label && <FormLabel label={label} required={required} requiredMark={requiredMark} />}
        <Form.Item name={name} rules={memoizedCreateRules} {...(defaultValue && { initialValue: defaultValue })}>
          <TextArea rows={5} placeholder={placeholder} maxLength={maxLength} disabled={disabled} />
        </Form.Item>
      </>
    </TextAreaContainer>
  );
};

export default CustomTextArea;

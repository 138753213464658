import { DashboardProvider } from './context/DashboardProvider';
import Dashboard from './Dashboard';

const DashboardWrapper = () => (
  <DashboardProvider>
    <Dashboard></Dashboard>
  </DashboardProvider>
);

export default DashboardWrapper;

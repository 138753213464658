import styled from '@emotion/styled';
import { Col, Row } from 'antd';

const StyledRow = styled(Row)`
  padding: 20px;
  .stat-label {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
  }
`;

const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const StyledChart = styled('div')`
  width: 150px;
`;

const StyledChartTitle = styled('div')`
  padding: 20px;

  font-family: 'Lexend';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  /* gray/900 */

  color: #111827;
`;

export { StyledRow, StyledChart, StyledChartTitle, StyledCol };

import React, { ReactElement, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { changeCustomerPasswordApi } from '../../../api/axios-api';
import { ApiStatus } from '../../../common/rootTypes';
import { GlobalContext } from '../../../context/GlobalProvider';

export type ResetCustomerPasswordRequest = {
  password: string;
  token: string;
  email: string;
};

type ChangeCustomerPasswordContextProp = {
  changePassword: ((resetRequest: ResetCustomerPasswordRequest) => Promise<void>) | null;
  cpAPIStatus: ApiStatus;
  cpError: string | null;
};

export const initialState: ChangeCustomerPasswordContextProp = {
  changePassword: null,
  cpAPIStatus: ApiStatus.idle,
  cpError: null,
};

export const ChangeCustomerPasswordContext = React.createContext<ChangeCustomerPasswordContextProp>(initialState);

export const ChangeCustomerPasswordProvider = (props: { children: ReactElement | ReactElement[] }) => {
  const { setSpinning } = useContext(GlobalContext);

  const { children } = props;
  const [apiStatus, setApiStatus] = useState<ApiStatus>(ApiStatus.idle);

  const [error, setError] = useState<string | null>(null);

  const navigate = useNavigate();

  const changePassword = async (cpRequest: ResetCustomerPasswordRequest) => {
    setError(null);
    setApiStatus(ApiStatus.pending);
    setSpinning && setSpinning(true);

    try {
      const response = await changeCustomerPasswordApi(cpRequest);
      if (response.status == 200) {
        setApiStatus(ApiStatus.succeeded);
        setSpinning && setSpinning(false);
      } else {
        //set error code
        setApiStatus(ApiStatus.failed);
        setError('Please try again!');
        setSpinning && setSpinning(false);
      }
    } catch (error) {
      setApiStatus(ApiStatus.failed);
      setSpinning && setSpinning(false);

      setError('Please try again!');
    }
  };

  const store: ChangeCustomerPasswordContextProp = {
    changePassword,
    cpAPIStatus: apiStatus,
    cpError: error,
  };

  return <ChangeCustomerPasswordContext.Provider value={store}>{children}</ChangeCustomerPasswordContext.Provider>;
};

import styled from '@emotion/styled';

const TextFieldContainer = styled('div')`
  overflow: hidden;

  input {
    font-size: 16px;
    color: #0e1936;
  }

  .ant-input[disabled] {
    background: #fff;
    color: #7d8293;
  }
`;

export { TextFieldContainer };

import styled from '@emotion/styled';

type TextAreaStyleProps = {
  width: number | string;
};

const TextAreaContainer = styled.div<TextAreaStyleProps>`
  width: ${(p) => (typeof p.width === 'number' ? `${p.width}rem` : p.width)};
  display: flex;
  flex-direction: column;

  .ant-input[disabled] {
    background: #fff;
    color: #7d8293;
  }
`;

export { TextAreaContainer };

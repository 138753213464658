import { Link } from 'react-router-dom';
import GetBackImg from '../../../images/get_back.png';
import { StyledContainer, StyledSimpleButton, StyledTitle } from './styles';

const GetBack = () => {
  return (
    <StyledContainer>
      <img src={GetBackImg} />
      <StyledTitle className="contentTitle">{'We will get back to you!'}</StyledTitle>
      <div>{'Thank you for choosing FitClub. Our team will send you an email with the details.'}</div>
      <StyledSimpleButton type="primary" className={'primaryBtn'}>
        <Link to="/">Return to homepage</Link>
      </StyledSimpleButton>
    </StyledContainer>
  );
};

export default GetBack;

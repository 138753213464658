import styled from '@emotion/styled';
import { Form, Row } from 'antd';

const StyledDiv = styled.div`
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  text-align: left;

  padding-bottom: 20px;
`;

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background: #eef2ff;
`;

const StyledFormContainer = styled.div`
  margin: 2% 10% 0 10%;
`;

const StyledForm = styled(Form)`
  background: #fff;
  border-radius: 15px;
  padding: 20px 50px;

  text-align: left;
`;

const StyledFormTitle = styled(Row)`
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
`;

const StyledFormSubtitle = styled(Row)`
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  padding: 5px 0;
`;

const StyledImage = styled.img`
  max-width: auto%;
  height: 100%;
`;

const StyledImageContainer = styled.div`
  height: 100vh;
`;

const StyledButtonRow = styled(Row)`
  padding: 20px 0;
`;

export {
  StyledDiv,
  StyledContainer,
  StyledFormContainer,
  StyledForm,
  StyledFormTitle,
  StyledFormSubtitle,
  StyledImageContainer,
  StyledImage,
  StyledButtonRow,
};

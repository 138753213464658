import React, { ReactElement, useContext, useState } from 'react';
import { getActiveUsersApi, getAvgStepsApi, getStepsForRangeApi } from '../../../api/axios-api';
import { ApiStatus } from '../../../common/rootTypes';
import { GlobalContext } from '../../../context/GlobalProvider';

type ReportContextProp = {
  getActiveUsers: ((month: number) => Promise<void>) | null;
  getAPIStatus: ApiStatus;
  selectedActiveUsers: ActiveUserResponse[] | null;
  getAvgSteps: ((startdate: string, endDate: string) => Promise<void>) | null;
  selectedAvgSteps: AverageUserStepsResponse[] | null;
  getStepsForRange: ((startdate: string, endDate: string) => Promise<void>) | null;
  selectedStepsForRange: StepsForDateRangeResponse[] | null;
};

export const initialState: ReportContextProp = {
  getActiveUsers: null,
  getAPIStatus: ApiStatus.idle,
  selectedActiveUsers: [],
  getAvgSteps: null,
  selectedAvgSteps: null,
  getStepsForRange: null,
  selectedStepsForRange: null,
};

export type ActiveUserResponse = {
  firstName: string;
  lastName: string;
};

export type AverageUserStepsResponse = {
  firstName: string;
  lastName: string;
  avgSteps: number;
};

export type StepsForDateRangeResponse = {
  steps: number;
  date: string;
};

export const ReportContext = React.createContext<ReportContextProp>(initialState);

export const ReportProvider = (props: { children: ReactElement | ReactElement[] }) => {
  const { setSpinning } = useContext(GlobalContext);

  const { children } = props;
  const [apiStatus, setApiStatus] = useState<ApiStatus>(ApiStatus.idle);
  const [selectedActiveUsers, setSelectedActiveUsers] = useState<ActiveUserResponse[] | null>(null);
  const [avgSteps, setAvgSteps] = useState<AverageUserStepsResponse[] | null>(null);
  const [stepsForRange, setStepsForRange] = useState<StepsForDateRangeResponse[] | null>(null);

  const getActiveUsers = async (month: number) => {
    setApiStatus(ApiStatus.pending);
    setSpinning && setSpinning(true);

    try {
      const response = await getActiveUsersApi(month);
      if (response.status == 200) {
        setSelectedActiveUsers(response.data.body);
        setApiStatus(ApiStatus.succeeded);
      } else {
        //set error code
        setApiStatus(ApiStatus.failed);
      }
      setSpinning && setSpinning(false);
    } catch (error) {
      setSpinning && setSpinning(false);
    }
  };

  const getAvgSteps = async (startdate: string, endDate: string) => {
    setApiStatus(ApiStatus.pending);
    setSpinning && setSpinning(true);

    try {
      const response = await getAvgStepsApi(startdate, endDate);
      if (response.status == 200) {
        setAvgSteps(response.data.body);
        setApiStatus(ApiStatus.succeeded);
      } else {
        //set error code
        setApiStatus(ApiStatus.failed);
      }
      setSpinning && setSpinning(false);
    } catch (error) {
      setSpinning && setSpinning(false);
    }
  };

  const getStepsForRange = async (startdate: string, endDate: string) => {
    setApiStatus(ApiStatus.pending);
    setSpinning && setSpinning(true);

    try {
      const response = await getStepsForRangeApi(startdate, endDate);
      if (response.status == 200) {
        setStepsForRange(response.data.body);
        setApiStatus(ApiStatus.succeeded);
      } else {
        //set error code
        setApiStatus(ApiStatus.failed);
      }
      setSpinning && setSpinning(false);
    } catch (error) {
      setSpinning && setSpinning(false);
    }
  };

  const store: ReportContextProp = {
    getActiveUsers,
    getAPIStatus: apiStatus,
    selectedActiveUsers: selectedActiveUsers,
    getAvgSteps,
    selectedAvgSteps: avgSteps,
    getStepsForRange,
    selectedStepsForRange: stepsForRange,
  };

  return <ReportContext.Provider value={store}>{children}</ReportContext.Provider>;
};

import { SignupProvider } from '../Signup/context/SignupProvider';
import VerifyEmailMessage from './VerifyEmailMessage';

const VerifyEmailMessageWrapper = () => (
  <SignupProvider>
    <VerifyEmailMessage></VerifyEmailMessage>
  </SignupProvider>
);

export default VerifyEmailMessageWrapper;

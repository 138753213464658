import moment, { Moment } from 'moment';

export const isEmail = (email: string) => {
  return /[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/.test(email);
};

export const dateInUTCDateString = (date: Moment | string) => {
  return `${moment(date).utc().format()}`;
};

export const dateWithTimeFormat = (date: Moment | string) => {
  return `${moment(date).format('DD MMM YYYY, h:mmA')}`;
};

export const dateFormat = (date: Moment | string) => {
  return `${moment(date).format('DD MMM YYYY')}`;
};

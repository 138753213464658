/* eslint-disable no-inline-styles/no-inline-styles */
import { Col, Layout, List, Menu, Row, Table, Tabs, Tag } from 'antd';
import { Link } from 'react-router-dom';
import {
  StyledChallenges,
  StyledContainer,
  StyledChallengsTitleCol,
  StyledRow,
  StyledHeader,
  StyledHeaderText,
  StyledPreText,
  StyledTag,
  StyledSubHeader,
  StyledListItem,
  StyledTable,
  StyledMenu,
  StyledCol,
} from './styles';
import { UserOutlined } from '@ant-design/icons';
import ChallengeListCard from './ChallengeListCard';
import ChallengeDetails from './ChallengeDetails';
import { useContext, useEffect, useState } from 'react';
import { ChallengeContext, ChallengeProvider, CompanyChallenge } from './context/ChallengeProvider';
import moment from 'moment';
import ChallengeAnalytics from './ChallengeAnalytics';
import { ColumnsType } from 'antd/lib/table';
import SimpleButton from '../../components/buttons/SimpleButton/SimpleButton';
import CreateChallenge from './CreateChallenge';

const Challenge = () => {
  const { companyChallenges, loadCompanyChallenges, loadCompanyChallengeDetails, selectedChallenge } =
    useContext(ChallengeContext);

  const [selectedCard, setSelectedCard] = useState<string | null>(null);

  const [listingChallenges, setListingChallenges] = useState<CompanyChallenge[] | null>(null);
  const [isChallengeCreateModalVisible, setChallengeCreateModalVisible] = useState<boolean>(false);

  const items = [
    {
      label: (
        <Link onClick={() => setListingChallenges(companyChallenges)} to={''}>
          {'All Challenges'}
        </Link>
      ),
      key: 'item-1',
    },
    {
      label: (
        <Link
          onClick={() => {
            setListingChallenges(companyChallenges.filter((challege) => moment(challege.endDate).isSameOrAfter()));
          }}
          to={''}
        >
          {'Ongoing Challenges'}
        </Link>
      ),
      key: 'item-2',
    },
    {
      label: (
        <Link
          onClick={() => {
            setListingChallenges(companyChallenges.filter((challege) => moment(challege.endDate).isBefore()));
          }}
          to={''}
        >
          {'Past Challenges'}
        </Link>
      ),
      key: 'item-3',
    },
  ];

  useEffect(() => {
    loadCompanyChallenges && loadCompanyChallenges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (challengeId: string) => {
    loadCompanyChallengeDetails && loadCompanyChallengeDetails(challengeId);
    setSelectedCard(challengeId);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
  ];

  return (
    <StyledContainer>
      <Row>
        <Col span={16}>
          <StyledMenu mode="horizontal" items={items} defaultActiveFirst={true}></StyledMenu>
        </Col>
        <StyledCol span={8}>
          <SimpleButton type="primary" className="primaryBtn" onClick={() => setChallengeCreateModalVisible(true)}>
            + Create a Challenge
          </SimpleButton>
        </StyledCol>
      </Row>
      <StyledChallenges>
        <StyledRow>
          <StyledChallengsTitleCol span={9}>
            <List
              size="large"
              dataSource={listingChallenges || companyChallenges}
              renderItem={(item) => {
                const isActive = item.challengeId === selectedCard;
                const today = moment();
                const daysLeft = moment(item.endDate).diff(today, 'days');
                return (
                  <StyledListItem isactive={isActive}>
                    <ChallengeListCard
                      name={item.name}
                      daysLeft={daysLeft}
                      challengeId={item.challengeId}
                      onClick={handleClick}
                      isactive={isActive}
                    />
                  </StyledListItem>
                );
              }}
            />
          </StyledChallengsTitleCol>
          {selectedChallenge && (
            <Col span={15}>
              <StyledHeader>
                <StyledPreText>One-time Challenge</StyledPreText>
                <StyledHeaderText>
                  <>{selectedChallenge?.name}</>
                  <StyledTag color="#D1FAE5">
                    {moment(selectedChallenge.endDate).isSameOrAfter() &&
                      `Ending in ${moment(selectedChallenge.endDate).diff(moment(), 'days')} Days`}
                  </StyledTag>
                </StyledHeaderText>
              </StyledHeader>
              <Tabs type="card">
                <Tabs.TabPane tab="Details" key="item-1">
                  <ChallengeDetails
                    createdDate={selectedChallenge.startDate}
                    startDate={selectedChallenge.startDate}
                    endDate={selectedChallenge.endDate}
                    challengeType={selectedChallenge.workoutType}
                    instructions={selectedChallenge.instructions}
                    rewards={selectedChallenge.rewards}
                    challengeId={selectedChallenge.challengeId}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Analytics" key="item-2">
                  <ChallengeAnalytics
                    numberOfCompanyUsers={selectedChallenge.noOfCompanyUsers}
                    numberOfChallengeParticipants={selectedChallenge.totalParticipants}
                    challengeParticipantPercentage={selectedChallenge.participantPercentage}
                    averageDailyPoints={selectedChallenge.averageDailyPoints}
                    workoutUnit={selectedChallenge.workoutUnit}
                    completedUsersPercentage={selectedChallenge.completedUsersPercentage}
                    challengeWorkoutPoints={selectedChallenge.workoutPoints}
                  />
                  <StyledSubHeader>Leaderboard</StyledSubHeader>
                  <StyledTable
                    columns={columns}
                    dataSource={selectedChallenge.participantStats.map((participant, i) => ({
                      key: i,
                      name: participant.firstName,
                    }))}
                  />
                </Tabs.TabPane>
              </Tabs>
            </Col>
          )}
        </StyledRow>
      </StyledChallenges>
      {isChallengeCreateModalVisible && (
        <CreateChallenge
          isModalVisible={isChallengeCreateModalVisible}
          handleCancel={() => setChallengeCreateModalVisible(false)}
        />
      )}
    </StyledContainer>
  );
};

export default Challenge;

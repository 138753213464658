import styled from '@emotion/styled';
import { Card } from 'antd';

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;

  // height: 208px;

  background: #ffffff;

  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
`;

const StyledFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px 24px;

  width: 100%;

  background: #f9fafb;
`;

const StyledTitleSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px 24px;

  width: 100%;
`;

const StyledParticipantSection = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 8px 18px;

  width: 100%;
`;

const StyledSmallTitle = styled.div`
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #6b7280;
`;

const StyledMainTitle = styled.div`
  padding-top: 4px;
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 32px;
  /* identical to box height, or 133% */

  /* gray/900 */

  color: #111827;
`;

const StyledParticipants = styled.div`
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 143% */

  color: #6b7280;

  display: flex;
  flex-direction: column;
  justify-content: center;
  // height: 40px;
  padding-left: 20px;
`;

export {
  StyledCard,
  StyledFooter,
  StyledTitleSection,
  StyledParticipantSection,
  StyledSmallTitle,
  StyledMainTitle,
  StyledParticipants,
};

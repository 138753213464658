import styled from '@emotion/styled';

const StyledStepsContent = styled('div')`
  padding: 50px 0 5vh 0;
`;

const StyledTitle = styled('div')`
  text-align: left;

  padding: 10px 0;
`;

const StyledDescription = styled('div')`
  text-align: left;

  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  padding: 10px 0;

  width: 32vw;
`;

export { StyledStepsContent, StyledTitle, StyledDescription };

import React from 'react';
import { Col, Row } from 'antd';
import { HelperText, StyledFormLabel } from './FormLabel.styles';
import { IFormLabelProps } from './FormLabel.type';

const FormLabel = ({ label, required, requiredMark = false, helperText }: IFormLabelProps) => {
  return (
    <StyledFormLabel>
      <Row>
        <Col>
          {label}
          {required && requiredMark && <span>*</span>}
        </Col>
        {helperText && (
          <Col>
            <HelperText>{helperText}</HelperText>
          </Col>
        )}
      </Row>
    </StyledFormLabel>
  );
};

export default FormLabel;

import { useCallback, useEffect, useMemo } from 'react';
import { Form, Select } from 'antd';
import { DropdownContainer, StyledSelect } from './Dropdown.styles';
import { IDropdownProps } from './Dropdown.type';
import { Rule } from 'antd/lib/form';
import FormLabel from '../FormLabel/FormLabel';

const { Option } = Select;

const Dropdown = ({
  name,
  label,
  items,
  required = true,
  requiredMark = false,
  requiredValMsg = 'Required',
  valueType = 'number',
  disabled,
  multiSelect = false,
  preventSearch = false,
  readonly = false,
  defaultValue,
  placeHolder = readonly ? '-' : 'Please select',
  onChange,
  overrideFormItemName = false,
  children,
  validation = [],
  allowSearch = false,
  ...rest
}: IDropdownProps) => {
  // create validation rules
  const createRules = useCallback(() => {
    const rules: Rule[] = [...validation];
    let message: string | undefined = undefined;
    if (required) {
      message = requiredValMsg;
      rules.push({
        required,
        message,
        whitespace: true,
        type: valueType,
      });
    }
    return rules;
  }, [required, requiredValMsg, valueType, validation]);

  const memoizedCreateRules = useMemo(() => createRules(), [createRules]);

  return (
    <DropdownContainer id={name as string}>
      {/* dropdown label */}
      {label && <FormLabel label={label} required={required} requiredMark={requiredMark}></FormLabel>}

      <Form.Item
        rules={memoizedCreateRules}
        {...(overrideFormItemName ? {} : { name: name })}
        initialValue={defaultValue}
      >
        {/* <StyledSelect placeholder={placeHolder} disabled={disabled} onChange={() => console.log('')} {...rest}>
          {children ||
            items?.map((value, index) => (
              <Option key={index} value={value.id}>
                {value.text}
              </Option>
            ))}
        </StyledSelect> */}
        <Select
          disabled={disabled}
          onChange={onChange}
          defaultValue={defaultValue?.toString()}
          filterOption={(input, option) => {
            return (option?.value as string).toLowerCase().includes(input);
          }}
          optionFilterProp="children"
          {...(allowSearch ? { showSearch: true } : {})}
          placeholder={placeHolder}
        >
          {children ||
            items?.map((value, index) => (
              <Option key={index} value={value.id}>
                {value.text}
              </Option>
            ))}
        </Select>
      </Form.Item>
    </DropdownContainer>
  );
};

export default Dropdown;

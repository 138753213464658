import { Col, Form, message, Row } from 'antd';
import { useCallback, useContext, useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Link } from 'react-router-dom';
import { ApiStatus } from '../../common/rootTypes';
import TextField from '../../components/Forms/TextField/TextField';
import { LoginContext, LoginRequest } from './context/LoginProvider';
import {
  StyledContainer,
  StyledError,
  StyledLoginForm,
  StyledLogo,
  StyledSimpleButton,
  StyledSubTitle,
  StyledTitle,
  StyledCol,
} from './styles';

const gutterh = 16;
const gutterv = 24;
const largeColspan = 24;

const Login = () => {
  const fullHostname = window.location.hostname;
  const parts = fullHostname.split('.');
  const subdomain = parts.length >= 2 ? parts[0] : '';

  const { login, loginError, requestChangePassword, requestChangePasswordApiStatus } = useContext(LoginContext);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [form] = Form.useForm();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('yourAction');

    form
      .validateFields()
      .then(() => {
        const { email, password } = form.getFieldsValue();
        const payload: LoginRequest = {
          email,
          password,
          subdomain,
          token,
        };
        login && login(payload);
      })
      .catch((err) => {
        // handle errors
      });
  }, [executeRecaptcha, form, login, subdomain]);

  const handleForgotPassword = () => {
    const email = form.getFieldValue('email');
    if (email && requestChangePassword) {
      requestChangePassword(email);
    }
  };

  useEffect(() => {
    if (requestChangePasswordApiStatus === ApiStatus.succeeded) {
      message.info(`Email has sent to your email ${form.getFieldValue('email')}`);
    }
  }, [form, requestChangePasswordApiStatus]);

  return (
    <StyledContainer>
      <StyledLogo>FitClub</StyledLogo>

      <StyledLoginForm form={form} name="loginForm" preserve={false}>
        <StyledTitle>Log in for FitClub</StyledTitle>
        <StyledSubTitle>
          Log in or <Link to="/signup">Create a new account</Link>
        </StyledSubTitle>

        <Row gutter={[gutterv, gutterh]}>
          <Col span={largeColspan}>
            <TextField name={'email'} label={'Work Email Address'} type="email" placeholder="johndoe@sephora.com" />
          </Col>
        </Row>
        <Row gutter={[gutterv, gutterh]}>
          <Col span={largeColspan}>
            <TextField name={'password'} label={'Password'} type="password" placeholder="Enter password" />
          </Col>
        </Row>
        {loginError && <StyledError>{loginError}</StyledError>}

        <Row gutter={[gutterv, gutterh]}>
          <StyledCol span={largeColspan} onClick={handleForgotPassword}>
            Forgot your password?
          </StyledCol>
        </Row>

        <Row gutter={[gutterv, gutterh]}>
          <Col span={largeColspan}>
            <StyledSimpleButton type="primary" onClick={handleReCaptchaVerify}>
              Log in
            </StyledSimpleButton>
          </Col>
        </Row>
      </StyledLoginForm>
    </StyledContainer>
  );
};

export default Login;

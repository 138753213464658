import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { FormInstance } from 'antd';
import { useContext } from 'react';
import { OnboardContext } from '../context/OnboardProvider';
import CheckoutForm from './CheckoutForm';

interface ISubscription {
  prev: () => void;
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string);

const Subscription = ({ prev }: ISubscription) => {
  const { subscription } = useContext(OnboardContext);

  const options = {
    clientSecret: subscription?.clientSecret,
    loader: 'always' as const,
  };
  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm prev={prev} />
    </Elements>
  );
};

export default Subscription;

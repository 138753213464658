import { SignupProvider } from '../Signup/context/SignupProvider';
import ChangePasswordOTP from './ChangePasswordOTP';

const VerifyEmailWrapper = () => (
  <SignupProvider>
    <ChangePasswordOTP></ChangePasswordOTP>
  </SignupProvider>
);

export default VerifyEmailWrapper;

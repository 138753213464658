import { Col, FormInstance, Radio, RadioChangeEvent, Row, Tag } from 'antd';
import { StyledTitle, StyledRadio, StyledRow, StyledTemplateTitle, StyledCard, StyledCardContent } from './styles';
import { ReactNode, useContext } from 'react';
import { ChallengeContext, Template, WorkoutType } from '../context/ChallengeProvider';

export interface IChallengeType {
  form: FormInstance;
  handleSeletecteTemplate: (templateId: string, type: string) => void;
  handleCustom: (isCustom: boolean) => void;
}

const colorsList = ['#EBFFF2', '#FFFDEB', '#EBFFFF'];

const ChallengeType = ({ form, handleSeletecteTemplate, handleCustom }: IChallengeType) => {
  const { templates, loadTemplates, predefinedTemplates } = useContext(ChallengeContext);

  const onChange = (e: RadioChangeEvent) => {
    form.setFieldsValue({ challengeType: e.target.value });
    loadTemplates && loadTemplates(e.target.value);
  };

  const onChangeTemplate = (e: RadioChangeEvent) => {
    if (e.target.value === '-1') {
      handleSeletecteTemplate('', '');
      handleCustom(true);
    } else {
      handleSeletecteTemplate(e.target.value, selectedTemplate);
      handleCustom(false);
    }
  };

  const selectedTemplate = form.getFieldValue('challengeType') || 'walking';
  const allTemplates = selectedTemplate
    ? [...predefinedTemplates[selectedTemplate as WorkoutType], ...templates]
    : templates;

  return (
    <>
      <StyledTitle>{'Choose the preferred type of exercise for the challenge:'}</StyledTitle>
      <StyledRadio onChange={onChange} defaultValue={'walking'}>
        <StyledRow>
          <Col span={12}>
            <Radio value={'walking'}>{'Walking (Step Challenge)'}</Radio>
          </Col>
          <Col span={12}>
            <Radio value={'running'}>{'Running'}</Radio>
          </Col>
        </StyledRow>
        <StyledRow>
          <Col span={12}>
            <Radio value={'cycling'}>{'Cycling'}</Radio>
          </Col>
          <Col span={12}>
            <Radio disabled value={'general'}>
              {'General Exercises'}
            </Radio>
          </Col>
        </StyledRow>
      </StyledRadio>

      <StyledTemplateTitle>{'Select a template or create a custom challenge:'}</StyledTemplateTitle>

      <Radio.Group onChange={onChangeTemplate}>
        <Row>
          {allTemplates.map((template, i) => (
            <Col span={12} key={template.id}>
              <StyledCard
                color={colorsList[i] && template.id !== '-1' ? colorsList[i] : '#fff'}
                border={template.id === '-1'}
              >
                <Radio value={template.id}>
                  <div className="name">{template.name}</div>
                  <StyledCardContent>
                    {template.id !== '-1'
                      ? `A ${template.workoutType} challenge. Let's achieve ${
                          template.workoutType != 'walking'
                            ? `${Math.round(template.workoutPoints * 0.00062137)} mile`
                            : template.workoutPoints
                        } ${template.workoutUnit}`
                      : template.description}
                  </StyledCardContent>
                  {template.tags}
                </Radio>
              </StyledCard>
            </Col>
          ))}
        </Row>
      </Radio.Group>
    </>
  );
};

export default ChallengeType;

import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  changePasswordWithOTPApi,
  getInvitationDetailsApi,
  signupApi,
  verifyEmailApi,
} from '../../../../api/axios-api';
import { ApiStatus } from '../../../../common/rootTypes';
import { GlobalContext } from '../../../../context/GlobalProvider';

export type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
};

export type SignupRequest = {
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  companyName: string;
  numberOfEmployees: number;
  industry: string;
  countryRegion: string;
  password: string;
  subdomain: string;
  invitationCode?: string;
};

export type ChangeOTPPasswordRequest = {
  firstName: string;
  lastName: string;
  email: string;
  newPassword: string;
  otp: string;
};

type SignupContextProp = {
  signup: ((signup: SignupRequest) => Promise<void>) | null;
  signupStatus: ApiStatus;
  signedEmail: string;
  verifyEmail: ((otp: string, email: string) => Promise<void>) | null;
  changePasswordWithOTP: ((signup: ChangeOTPPasswordRequest) => Promise<void>) | null;
  initialFormValues: InitialFormValues | null;
};

type InitialFormValues = {
  email: string;
  contactNumber: string;
  invitationCode: string;
};

export const initialState: SignupContextProp = {
  signup: null,
  signupStatus: ApiStatus.idle,
  signedEmail: 'Your email address',
  verifyEmail: null,
  changePasswordWithOTP: null,
  initialFormValues: null,
};

export const SignupContext = React.createContext<SignupContextProp>(initialState);

export const SignupProvider = (props: { children: ReactElement | ReactElement[] }) => {
  const { setSpinning } = useContext(GlobalContext);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { children } = props;
  const [apiStatus, setApiStatus] = useState<ApiStatus>(ApiStatus.idle);
  const [signedEmail, setSignedEmail] = useState<string>('your email address');
  const [initialFormValues, setInitialFormValues] = useState<InitialFormValues | null>(null);

  const signup = async (signup: SignupRequest) => {
    setSpinning && setSpinning(true);
    setApiStatus(ApiStatus.pending);
    setSignedEmail(signup.email);

    try {
      const response = await signupApi(signup);
      if (response.status == 200) {
        setApiStatus(ApiStatus.succeeded);
        navigate('/verifyEmailMessage');
      } else {
        //set error code
        setApiStatus(ApiStatus.failed);
      }
      setSpinning && setSpinning(false);
    } catch (error) {
      setApiStatus(ApiStatus.failed);
      setSpinning && setSpinning(false);
    }
  };

  const verifyEmail = async (otp: string, email: string) => {
    setSpinning && setSpinning(true);
    setApiStatus(ApiStatus.pending);

    try {
      const response = await verifyEmailApi(otp, email);
      if (response.status == 200) {
        setApiStatus(ApiStatus.succeeded);

        localStorage.setItem('token', response.data.body.token);
        navigate('/onboard/0');
      } else {
        //set error code
        setApiStatus(ApiStatus.failed);
        navigate('/verifyEmailError');
      }
      setSpinning && setSpinning(false);
    } catch (error) {
      setApiStatus(ApiStatus.failed);
      setSpinning && setSpinning(false);
      navigate('/verifyEmailError');
    }
  };

  const changePasswordWithOTP = async (signup: ChangeOTPPasswordRequest) => {
    setSpinning && setSpinning(true);
    setApiStatus(ApiStatus.pending);

    try {
      const response = await changePasswordWithOTPApi(signup);
      if (response.status == 200) {
        setApiStatus(ApiStatus.succeeded);
        navigate('/');
      } else {
        //set error code
        setApiStatus(ApiStatus.failed);
      }
      setSpinning && setSpinning(false);
    } catch (error) {
      setApiStatus(ApiStatus.failed);
      setSpinning && setSpinning(false);
    }
  };

  useEffect(() => {
    const loadInvitationDetails = async () => {
      setSpinning && setSpinning(true);
      setApiStatus(ApiStatus.pending);

      const invitationCode = searchParams.get('invitationCode');

      if (!invitationCode) return;

      try {
        const response = await getInvitationDetailsApi(invitationCode);

        setInitialFormValues({
          contactNumber: response.data.body.contactNumber,
          email: response.data.body.contactEmail,
          invitationCode: response.data.body.invitationCode,
        });

        setApiStatus(ApiStatus.succeeded);
      } finally {
        setSpinning && setSpinning(false);
      }

      console.log(invitationCode);
    };

    loadInvitationDetails();
    setSpinning && setSpinning(false);
  }, []);

  const store: SignupContextProp = {
    signup,
    signupStatus: apiStatus,
    signedEmail: signedEmail,
    verifyEmail: verifyEmail,
    changePasswordWithOTP: changePasswordWithOTP,
    initialFormValues: initialFormValues,
  };

  return <SignupContext.Provider value={store}>{children}</SignupContext.Provider>;
};

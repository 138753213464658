import styled from '@emotion/styled';
import { Dropdown, Menu } from 'antd';
import { Header } from 'antd/lib/layout/layout';

const StyledHeader = styled(Header)`
  width: 100%;
  background: #fff;
  display: flex;
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  justify-content: space-between;

  border-bottom: 1px solid #d1d5db;
`;

const StyledLogo = styled.div`
  display: flex;
  align-items: center;

  font-family: 'Lexend';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 41px;
  /* identical to box height, or 228% */

  letter-spacing: 0.374px;

  /* Label Color/Light/Primary */

  color: #000000;

  a {
    color: #000;
  }
`;

const StyledMenuSection = styled.div`
  display: flex;
`;

const StyledMenu = styled(Menu)`
  width: 60vh;
`;

const StyledUserMenu = styled(Dropdown)`
  cursor: pointer;
`;
export { StyledHeader, StyledLogo, StyledMenuSection, StyledMenu, StyledUserMenu };

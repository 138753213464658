import { Avatar } from 'antd';
import { UserOutlined, AntDesignOutlined } from '@ant-design/icons';

import {
  StyledCard,
  StyledTitleSection,
  StyledParticipantSection,
  StyledSmallTitle,
  StyledMainTitle,
  StyledParticipants,
} from './styles';
import { Contributor } from '../context/DashboardProvider';

interface IStatCard {
  title: string;
  value: number;
  contributors: Contributor[];
}

const StatCard = ({ title, value, contributors }: IStatCard) => {
  return (
    <StyledCard>
      <StyledTitleSection>
        <StyledSmallTitle>{title}</StyledSmallTitle>
        <StyledMainTitle>{value.toLocaleString()}</StyledMainTitle>
      </StyledTitleSection>

      <StyledParticipants>Best Contributors</StyledParticipants>

      <StyledParticipantSection>
        <Avatar.Group
          maxCount={5}
          maxPopoverTrigger="click"
          size="large"
          maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf', cursor: 'pointer' }}
        >
          {contributors.map((contributor, i) => (
            <Avatar src={`${process.env.REACT_APP_AVATAR_BUCKET}/${contributor.profilePicture}`} key={i}>
              {contributor.firstName.charAt(0).toUpperCase()}
            </Avatar>
          ))}
        </Avatar.Group>
      </StyledParticipantSection>
    </StyledCard>
  );
};

export default StatCard;

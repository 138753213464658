/* eslint-disable prefer-const */
import React, { ReactElement, useContext, useState } from 'react';
import { loadLeaderboardApi } from '../../../api/axios-api';
import { ApiStatus } from '../../../common/rootTypes';
import { GlobalContext } from '../../../context/GlobalProvider';

export type Member = {
  id: string;
  name: string;
};

export type LeaderboardResponse = {
  firstName: string;
  lastName: string;
  profilePicture: string;
  email: string;
  value: number;
};

type LeaderboardContextProp = {
  leaderboard: LeaderboardResponse[];
  fetchLeaderboard: ((month: number) => Promise<void>) | null;
  fetchLeaderboardStatus: ApiStatus;
};

export const initialState: LeaderboardContextProp = {
  leaderboard: [],
  fetchLeaderboard: null,
  fetchLeaderboardStatus: ApiStatus.idle,
};

export const LeaderboardContext = React.createContext<LeaderboardContextProp>(initialState);

export const LeaderboardProvider = (props: { children: ReactElement | ReactElement[] }) => {
  const { setSpinning } = useContext(GlobalContext);

  const { children } = props;
  const [leaderboard, setLeaderboard] = useState<LeaderboardResponse[]>([]);
  const [apiStatus, setApiStatus] = useState<ApiStatus>(ApiStatus.idle);

  const fetchLeaderboard = async (month: number) => {
    setSpinning && setSpinning(true);

    setApiStatus(ApiStatus.pending);

    try {
      let response = await loadLeaderboardApi(month);
      if (response.status == 200) {
        setLeaderboard(response.data.body);
        setApiStatus(ApiStatus.succeeded);
      } else {
        //set error code
        setApiStatus(ApiStatus.failed);
      }
      setSpinning && setSpinning(false);
    } catch (error) {
      setSpinning && setSpinning(false);
    }
  };

  const store: LeaderboardContextProp = {
    leaderboard,
    fetchLeaderboard,
    fetchLeaderboardStatus: apiStatus,
  };

  return <LeaderboardContext.Provider value={store}>{children}</LeaderboardContext.Provider>;
};

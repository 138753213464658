import styled from '@emotion/styled';
import { Col, List, Menu, Row, Table, Tag } from 'antd';

type ListItemProps = {
  isactive: boolean;
};

const StyledContainer = styled('div')`
  padding: 20px 0;
  min-width: 980px;

  .ant-menu-horizontal {
    border: none;
  }

  .ant-list-lg .ant-list-item {
    padding: 10px 0px; !important;
  }
`;

const StyledChallenges = styled('div')`
  background: #fff;
  border-radius: 8px;
`;

const StyledChallengsTitleCol = styled(Col)`
  border-right: 1px solid #f3f4f6;
`;

const StyledRow = styled(Row)`
  min-height: 500px;
  border: 1px solid #f3f4f6;
`;

const StyledHeader = styled('div')`
  padding: 20px 0 10px 20px;
  text-align: left;
`;
const StyledHeaderText = styled('div')`
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  /* gray/900 */

  color: #111827;
`;
const StyledPreText = styled('div')`
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* gray/400 */

  color: #9ca3af;
`;

const StyledTag = styled(Tag)`
  color: #065f46;
  margin: 0 15px;
  border-radius: 12px;
`;

const StyledSubHeader = styled(Row)`
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */

  /* gray/900 */

  color: #111827;

  padding: 20px;
`;

const StyledListItem = styled(List.Item)<ListItemProps>`
  background: ${(props) => (props.isactive ? '#4F46E5' : `#fff`)};
  border-bottom: 1px solid #f3f4f6;
  border-radius: 8px 0 0 0;
  min-height: 120px;
`;

const StyledTable = styled(Table)`
  padding: 20px;
`;

const StyledMenu = styled(Menu)`
  background: #f5f5f5;
  padding-bottom: 20px;
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
`;

const StyledCol = styled(Col)`
  text-align: right;
`;

export {
  StyledContainer,
  StyledChallenges,
  StyledChallengsTitleCol,
  StyledRow,
  StyledHeader,
  StyledHeaderText,
  StyledPreText,
  StyledTag,
  StyledSubHeader,
  StyledListItem,
  StyledTable,
  StyledMenu,
  StyledCol,
};

import { AntDesignOutlined, RightOutlined } from '@ant-design/icons';
import { Avatar, Col, Row } from 'antd';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import {
  StyledCard,
  StyledChallengeName,
  StyledPreText,
  StyledTextCol,
  StyledDateText,
  StyledAvatarCol,
  StyledChavronCol,
  StyledAvatar,
} from './styles';

export interface IChallengeListCard {
  name: string;
  daysLeft: number;
  challengeId: string;
  onClick: (challengeId: string) => void;
  isactive: boolean;
}

const ChallengeListCard = ({ name, daysLeft, challengeId, onClick, isactive }: IChallengeListCard) => {
  return (
    <StyledCard onClick={() => onClick(challengeId)}>
      <Row>
        <StyledAvatarCol span={4}>
          <StyledAvatar icon={<DirectionsWalkIcon fontSize="small" />} />
        </StyledAvatarCol>
        <StyledTextCol span={18}>
          <StyledPreText isactive={isactive}>One-time Challenge</StyledPreText>
          <StyledChallengeName isactive={isactive}>{name}</StyledChallengeName>
          <StyledDateText isactive={isactive}>{daysLeft >= 0 ? `${daysLeft} days left` : ''}</StyledDateText>
        </StyledTextCol>
        <StyledChavronCol isactive={isactive}>
          <RightOutlined />
        </StyledChavronCol>
      </Row>
    </StyledCard>
  );
};

export default ChallengeListCard;

import styled from '@emotion/styled';
import { Col, Row } from 'antd';

const StyledTitle = styled.div`
  display: flex;
  padding: 30px 0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;

  color: #000000;
`;

const StyledWelcome = styled.div`
  padding: 30px 0;
  text-align: left;

  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  /* identical to box height, or 133% */

  /* gray/900 */

  color: #111827;
`;

const StyledBanner = styled.div`
  display: flex;
  padding: 47px 40px;
  border-radius: 12px;
  width: 100%;
  background: #eef2ff;
`;

const StyledBannerContent = styled.div`
  text-align: left;
`;

const StyledBannerTitle = styled.div`
  padding-bottom: 20px;
`;

const StyledBannerDesc = styled.div`
  padding: 0px 0;
  padding-bottom: 49px;
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  width: 80%;
`;

const StyledSection = styled.div`
  text-align: left;
  padding: 20px 0;
`;

const StyledMonthStat = styled(Row)`
  padding: 0 10px;
  background: #fff;

  .ant-col:not(:first-child) {
    border-left: 1px solid #e5e7eb;
  }

  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
`;

const StyledMonthStatSection = styled.div`
  text-align: left;
  padding: 20px 0;

  .date-container {
    display: flex;
    align-self: center;

    .dropdown-month {
      position: relative;
      bottom: 3px;
    }
  }

  .ant-select .ant-select-selector {
    background: #f5f5f5;
    border: none;

    font-family: 'Lexend';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height, or 133% */

    /* indigo/600 */

    color: #4f46e5;
  }
`;

const StyledCol = styled(Col)`
  padding: 10px 15px;
`;

const StyledColStats = styled(Col)`
  padding: 10px 0px;
  padding-right: 15px;
`;

const StyledStatText = styled.div`
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* gray/800 */

  color: #1f2937;
`;

const StyledStatValue = styled.div`
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  /* indigo/600 */

  color: #4f46e5;
`;

const StyledSubTitle = styled.div`
  padding-bottom: 30px;
`;

const StyledChallengeSummarySection = styled.div`
  text-align: left;
  padding: 20px 0;
`;

const StyledChallengeAnalyticCard = styled.div`
  background: #fff;
  border-bottom: 1px solid #e5e7eb;
`;

const StyledChallengeName = styled.div`
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */

  /* gray/900 */

  color: #111827;

  padding: 30px 25px 10px 25px;
`;

const StyledEmptyChallenges = styled.div`
  text-align: center;
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* gray/600 */

  color: #4b5563;

  padding: 30px 20px;

  background: #fff;

  border: 1px dashed #d1d5db;
  border-radius: 8px;
`;

const StyledContainer = styled('div')`
  padding: 20px 0;
  min-width: 980px;

  .ant-menu-horizontal {
    border: none;
  }

  .ant-list-lg .ant-list-item {
    padding: 10px 0px; !important;
  }
`;

const StyledDescription = styled('div')`
  padding-bottom: 10px;
`;

export {
  StyledTitle,
  StyledWelcome,
  StyledBanner,
  StyledBannerTitle,
  StyledBannerContent,
  StyledBannerDesc,
  StyledSection,
  StyledMonthStat,
  StyledMonthStatSection,
  StyledCol,
  StyledStatText,
  StyledStatValue,
  StyledSubTitle,
  StyledChallengeSummarySection,
  StyledChallengeAnalyticCard,
  StyledChallengeName,
  StyledEmptyChallenges,
  StyledColStats,
  StyledContainer,
  StyledDescription,
};

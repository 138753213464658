/* eslint-disable no-inline-styles/no-inline-styles */
import { Avatar, Col, Form, Row, Space } from 'antd';
import {
  StyledTopRowHeader,
  StyledTopRowText,
  StyledTopRow,
  StyledContentHeader,
  StyledContents,
  StyledCol,
  StyledAvatar,
} from './styles';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { ChallengeContext, ChallengeMode, ChallengeProvider, Reward } from '../context/ChallengeProvider';
import { dateFormat, dateWithTimeFormat } from '../../../common/utils';
import AppModal from '../../../components/Modal/AppModal';
import { useContext, useState } from 'react';
import CreateChallenge from '../CreateChallenge';
import moment from 'moment';

interface IChallengeDetails {
  createdDate: string;
  startDate: string;
  endDate: string;
  challengeType: string;
  instructions: string;
  rewards: Reward;
  challengeId: string;
}
const ChallengeDetails = ({
  createdDate,
  startDate,
  endDate,
  challengeType,
  instructions,
  rewards,
  challengeId,
}: IChallengeDetails) => {
  const { deleteChallenge } = useContext(ChallengeContext);

  const [isDeleteConfirmationModalVisible, setDeleteConfirmationModalVisible] = useState<boolean>(false);
  const [isEditChallengeModalVisible, setEditChallengeModalVisible] = useState<boolean>(false);

  const handleDelete = () => {
    deleteChallenge && deleteChallenge(challengeId);

    setDeleteConfirmationModalVisible(false);
  };

  return (
    <>
      <div>
        <StyledTopRow>
          <Col span={3}>
            <StyledTopRowHeader>CREATED</StyledTopRowHeader>
            <StyledTopRowText>{dateWithTimeFormat(createdDate)}</StyledTopRowText>
          </Col>
          <StyledCol span={3}>
            <StyledTopRowHeader>Exercise Type</StyledTopRowHeader>
            <StyledTopRowText>{challengeType}</StyledTopRowText>
          </StyledCol>
          <StyledCol span={3}>
            <StyledTopRowHeader>Time Interval</StyledTopRowHeader>
            <StyledTopRowText>Monthly</StyledTopRowText>
          </StyledCol>
          <StyledCol span={12}>
            <StyledTopRowHeader>Time Period</StyledTopRowHeader>
            <StyledTopRowText>{`${dateFormat(startDate)} - ${dateFormat(endDate)}`}</StyledTopRowText>
          </StyledCol>
          <Col span={3}>
            <Space>
              {moment(startDate).isAfter() && (
                <StyledAvatar>
                  <EditOutlined className="action_icon" onClick={() => setEditChallengeModalVisible(true)} />
                </StyledAvatar>
              )}
              <StyledAvatar>
                <DeleteOutlined className="action_icon" onClick={() => setDeleteConfirmationModalVisible(true)} />
              </StyledAvatar>
            </Space>
          </Col>
        </StyledTopRow>
        <StyledContentHeader>Instructions</StyledContentHeader>
        <StyledContents>
          <div dangerouslySetInnerHTML={{ __html: instructions }}></div>
        </StyledContents>
        <StyledContentHeader>Incentives/ Rewards</StyledContentHeader>
        <StyledContents>
          <div dangerouslySetInnerHTML={{ __html: rewards.rewards }}></div>
        </StyledContents>
        <StyledContents>
          <div dangerouslySetInnerHTML={{ __html: rewards.claims }}></div>
        </StyledContents>
      </div>
      {isDeleteConfirmationModalVisible && (
        <AppModal
          visible={isDeleteConfirmationModalVisible}
          handleCancel={() => setDeleteConfirmationModalVisible(false)}
          width={600}
          titleText={'Delete Challenge'}
          handleOk={handleDelete}
          overrideSubmitButtonText="Delete"
        >
          Are you sure you want to delete this challenge?
        </AppModal>
      )}
      {isEditChallengeModalVisible && (
        <CreateChallenge
          isModalVisible={isEditChallengeModalVisible}
          handleCancel={() => setEditChallengeModalVisible(false)}
          mode={ChallengeMode.EDIT}
        />
      )}
    </>
  );
};

export default ChallengeDetails;

import { Avatar, Menu, MenuProps } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { StyledHeader, StyledLogo, StyledMenu, StyledMenuSection, StyledUserMenu } from './styles';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useMemo, useState } from 'react';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

const items = [
  { label: <Link to={'/'}>{'Home'}</Link>, key: 'item-1' },
  { label: <Link to={'/challenge'}>{'Challenges'}</Link>, key: 'item-2' },
  { label: <Link to={'/leaderboard'}>{'Leaderboard'}</Link>, key: 'item-3' },
  { label: <Link to={'/reports'}>{'Reports'}</Link>, key: 'item-4' },
  { label: <Link to={'/settings'}>{'Settings'}</Link>, key: 'item-5' },
];

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [current, setCurrent] = useState('item-1');

  const menuItems = useCallback(
    () => [
      {
        key: 'logout',
        label: (
          <div
            onClick={() => {
              localStorage.clear();
              navigate('/login');
            }}
          >
            {'Logout'}
          </div>
        ),
      },
    ],
    []
  );

  const memoizedMenuItems: ItemType[] = useMemo(() => menuItems(), [menuItems]);

  const userMenu = <Menu items={memoizedMenuItems} />;

  const onClick: MenuProps['onClick'] = (e) => {
    setCurrent(e.key);
  };

  return (
    <StyledHeader>
      <StyledLogo className="logo">
        <Link onClick={() => setCurrent('item-1')} to={'/'}>
          {'FitClub'}
        </Link>
      </StyledLogo>
      {!location.pathname.includes('onboard') && !location.pathname.includes('getback') && (
        <StyledMenuSection>
          <StyledMenu selectedKeys={[current]} mode="horizontal" items={items} onClick={onClick} />

          <StyledUserMenu overlay={userMenu} trigger={['click']}>
            <div onClick={(e) => e.preventDefault()}>
              <Avatar size="small" icon={<UserOutlined />} />
            </div>
          </StyledUserMenu>
        </StyledMenuSection>
      )}
    </StyledHeader>
  );
};

export default Header;

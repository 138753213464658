import { useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import SimpleButton from '../../components/buttons/SimpleButton/SimpleButton';
import ChallengeCard from '../Challenge/ChallengeCard';
import CreateChallenge from '../Challenge/CreateChallenge';
import LeaderBoard from '../Leaderboard';
import { LeaderboardProvider } from '../Leaderboard/context/LeaderboardProvider';
import {
  StyledBanner,
  StyledWelcome,
  StyledBannerTitle,
  StyledBannerContent,
  StyledBannerDesc,
  StyledChallengeSection,
  StyledMonthStatSection,
  StyledMonthStat,
  StyledCol,
  StyledStatText,
  StyledStatValue,
  StyledSubTitle,
  StyledChallengeSummarySection,
  StyledChallengeAnalyticCard,
  StyledChallengeName,
  StyledEmptyChallenges,
  StyledColStats,
  StyledMessage,
} from './styles';
import BannerImg from '../../images/banner.png';
import { Col, Row } from 'antd';
import Dropdown from '../../components/Forms/Dropdown/Dropdown';
import { ChallengeProvider } from '../Challenge/context/ChallengeProvider';
import { DashboardContext } from './context/DashboardProvider';
import moment from 'moment';
import StatCard from './StatCard';
import ChallengeAnalytics from '../Challenge/ChallengeAnalytics';
import { months } from '../../common/rootTypes';

const Dashboard = () => {
  const currentMonth = Number(moment().format('M'));
  const currentMonthvalue = useMemo(() => months.filter((month) => month.id === currentMonth), [currentMonth]);

  const { getStats, selectedStats, getMonthChallenges, selectedChallenges } = useContext(DashboardContext);

  const [isChallengeCreateModalVisible, setChallengeCreateModalVisible] = useState<boolean>(false);

  useEffect(() => {
    getStats && getStats(currentMonth);
    getMonthChallenges && getMonthChallenges(currentMonth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <LeaderboardProvider>
        <StyledWelcome>{'Welcome to the FitClub!'}</StyledWelcome>
        <StyledBanner>
          <StyledBannerContent>
            <StyledBannerTitle className="contentTitle">
              {'Don’t have any challenges for your team yet?'}
            </StyledBannerTitle>
            <StyledBannerDesc>
              {
                'Create a challenge to get your team members active and fit. Bring team members together for a fun fitness activity.'
              }
            </StyledBannerDesc>
            <SimpleButton type="primary" className="primaryBtn" onClick={() => setChallengeCreateModalVisible(true)}>
              + Create a Challenge
            </SimpleButton>
          </StyledBannerContent>
          <img src={BannerImg} />
        </StyledBanner>

        <StyledMonthStatSection>
          <div className="date-container">
            <div className="contentTitle">In</div>
            <div className="dropdown-month">
              <Dropdown
                name="month"
                items={months}
                defaultValue={currentMonthvalue[0].text}
                onChange={(e) => {
                  getStats && getStats(Number(e));
                  getMonthChallenges && getMonthChallenges(Number(e));
                }}
              />
            </div>
          </div>

          <StyledMonthStat>
            <StyledCol span={8}>
              <StyledStatText>Total users that have signed up</StyledStatText>
              <StyledStatValue>{selectedStats?.signupUsers || 0}</StyledStatValue>
            </StyledCol>
            <StyledCol span={8}>
              <StyledStatText>No. Challenges created for the month</StyledStatText>
              <StyledStatValue>{selectedStats?.challengesCreated || 0}</StyledStatValue>
            </StyledCol>
            <StyledCol span={8}>
              <StyledStatText>Avg. particiapation for challenges</StyledStatText>
              <StyledStatValue>{`${selectedStats?.avgParticipation || 0}%`}</StyledStatValue>
            </StyledCol>
          </StyledMonthStat>
        </StyledMonthStatSection>

        <StyledChallengeSection>
          <StyledSubTitle className="contentTitle">{'Together we have achieved,'}</StyledSubTitle>
          <Row>
            <StyledColStats span={6}>
              <StatCard
                title="Total Distance"
                value={selectedStats?.totalDistance || 0}
                contributors={selectedStats?.distanceContributors || []}
              />
            </StyledColStats>
            <StyledColStats span={6}>
              <StatCard
                title="Total Steps"
                value={selectedStats?.totalSteps || 0}
                contributors={selectedStats?.stepContributors || []}
              />
            </StyledColStats>
            <StyledColStats span={6}>
              <StatCard
                title="Total Exercise Minutes"
                value={selectedStats?.totalExerciseMinutes || 0}
                contributors={selectedStats?.minuteContributors || []}
              />
            </StyledColStats>
            <StyledColStats span={6}>
              <StatCard
                title="Total Active Energy Burnt"
                value={selectedStats?.totalActiveEnergyBurnt || 0}
                contributors={selectedStats?.caloryContributors || []}
              />
            </StyledColStats>
          </Row>
        </StyledChallengeSection>

        {/* <StyledTitle>Leaderboard</StyledTitle> */}

        {/* <LeaderBoard /> */}
      </LeaderboardProvider>
      <StyledChallengeSummarySection>
        <StyledSubTitle className="contentTitle">{'Challenge summary'}</StyledSubTitle>
        {selectedChallenges && selectedChallenges?.length === 0 ? (
          <StyledEmptyChallenges>
            <StyledMessage>No ongoing challenges yet. Create a challenge to view the summary</StyledMessage>
            <SimpleButton type="primary" className="primaryBtn" onClick={() => setChallengeCreateModalVisible(true)}>
              + Create a Challenge
            </SimpleButton>
          </StyledEmptyChallenges>
        ) : (
          selectedChallenges?.map((challenge, i) => (
            <StyledChallengeAnalyticCard key={i}>
              <StyledChallengeName>{challenge.name}</StyledChallengeName>
              <ChallengeAnalytics
                numberOfCompanyUsers={challenge.noOfCompanyUsers}
                numberOfChallengeParticipants={challenge.totalParticipants}
                challengeParticipantPercentage={challenge.participantPercentage}
                averageDailyPoints={challenge.averageDailyPoints}
                workoutUnit={''}
                completedUsersPercentage={challenge.completedUsersPercentage}
                challengeWorkoutPoints={0}
              />
            </StyledChallengeAnalyticCard>
          ))
        )}
      </StyledChallengeSummarySection>

      {isChallengeCreateModalVisible && (
        <ChallengeProvider>
          <CreateChallenge
            isModalVisible={isChallengeCreateModalVisible}
            handleCancel={() => setChallengeCreateModalVisible(false)}
          />
        </ChallengeProvider>
      )}
    </>
  );
};

export default Dashboard;

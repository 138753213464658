import styled from '@emotion/styled';

const ProfileColumnContainer = styled('div')`
  display: flex;
  align-items: center;
`;

const DetailsContainer = styled('div')`
  margin-left: 16px;
  display: grid;
  align-items: center;
`;

const StyledName = styled('div')`
  color: #082354;
  font-size: 14px;
  font-weight: 500;
`;

const Subtitle = styled('div')`
  font-size: 12px;
  color: #585e6d;
  font-weight: 400;
`;

export { ProfileColumnContainer, DetailsContainer, StyledName, Subtitle };

import { FormInstance } from 'antd';
import React, { useEffect, useState } from 'react';
import { TagsInput } from 'react-tag-input-component';
import { isEmail } from '../../../common/utils';
import { StyledError, StyledContainer } from './styles';

interface IMultipleAdmin {
  form: FormInstance;
  admins?: string[];
}

const MultipleAdmin = ({ form, admins }: IMultipleAdmin) => {
  const [tags, setTags] = useState<string[]>(form.getFieldValue('admins'));
  const [error, setError] = useState<string>();

  const validate = (email: string, emailList: string[]) => {
    setError('');
    if (isEmail(email) && emailList.length < 2) {
      return true;
    } else {
      setError('Please enter 2 valid email addresses');
      return false;
    }
  };

  admins &&
    admins.length > 0 &&
    form.setFieldsValue({
      admins: admins,
    });

  return (
    <>
      <StyledContainer>
        <TagsInput
          seprators={[',']}
          value={tags}
          onChange={setTags}
          beforeAddValidate={validate}
          name="emails"
          onBlur={() => form.setFieldsValue({ admins: tags })}
        />
        <StyledError>{error}</StyledError>
      </StyledContainer>
    </>
  );
};

export default MultipleAdmin;

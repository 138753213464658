import styled from '@emotion/styled';

type CrownProps = {
  positon: 'first' | 'second' | 'third';
};

const LeaderboardContainer = styled.div`
  text-align: left;
  padding: 50px 100px;
`;

const StyledMonthStatSection = styled.div`
  text-align: left;
  padding-top: 20px;

  display: flex;
  align-self: center;

  .ant-select .ant-select-selector {
    background: #f5f5f5;
    border: none;

    font-family: 'Lexend';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height, or 133% */

    /* indigo/600 */

    color: #4f46e5;
  }

  .dropdown-month {
    position: relative;
    bottom: 3px;
  }
`;

const StyledDesciption = styled.div`
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* or 157% */

  /* gray/800 */

  color: #1f2937;

  padding-bottom: 10px;
`;

const StyledCrown = styled.span<CrownProps>`
  color: ${(props) => (props.positon === 'first' ? '#FFD700' : props.positon === 'second' ? '#C0C0C0 ' : `#CD7F32`)};
  padding-left: 5px;
`;

const StyledRank = styled.span`
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* gray/900 */

  color: #111827;
`;

const StyledScore = styled.span`
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* gray/500 */

  color: #6b7280;
`;

const StyledNoParticipation = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 50px;
`;

export {
  StyledMonthStatSection,
  StyledDesciption,
  LeaderboardContainer,
  StyledCrown,
  StyledRank,
  StyledScore,
  StyledNoParticipation,
};

import { Form } from 'antd';
import TextArea from '../../../components/Forms/TextArea/TextArea';
import AppModal from '../../../components/Modal/AppModal';
import TeamMembers from '../../Onboard/TeamMembers';

interface IEditMembers {
  handleCancel: () => void;
  handleOk: (members: string[]) => void;
}

const EditMembers = ({ handleCancel, handleOk }: IEditMembers) => {
  const [form] = Form.useForm();

  return (
    <AppModal
      visible={true}
      handleCancel={handleCancel}
      width={600}
      titleText={'Edit team members list'}
      handleOk={() => handleOk(form.getFieldValue('members'))}
    >
      {/* <Form form={form}>
        <TextArea name={'reason'} label={'Reason for cancelling subscription'} rows={0} required={true} />
      </Form> */}
      <TeamMembers form={form} />
    </AppModal>
  );
};

export default EditMembers;

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { LoginProvider } from './context/LoginProvider';
import Login from './Login';

const LoginWrapper = () => (
  <LoginProvider>
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_CAPTCHA_KEY as string}>
      <Login></Login>
    </GoogleReCaptchaProvider>
  </LoginProvider>
);

export default LoginWrapper;

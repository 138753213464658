import { StyledContainer, StyledContent, StyledHeader, StyledLogo, StyledSection, StyledTitle } from './styles';

const VerifyEmailError = () => {
  return (
    <>
      <StyledHeader>
        <StyledLogo className="logo">{'FitClub'}</StyledLogo>
      </StyledHeader>
      <StyledContainer>
        <StyledSection>
          <StyledTitle>Error!</StyledTitle>

          <StyledContent>The OTP you have provided is wrong. Please try again!</StyledContent>
        </StyledSection>
      </StyledContainer>
    </>
  );
};

export default VerifyEmailError;

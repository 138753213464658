import { Select } from 'antd';
import styled from '@emotion/styled';
import { IDropdownProps } from './Dropdown.type';

const DropdownContainer = styled('div')`
  display: flex;
  flex-direction: column;

  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: #fff;
    color: #7d8293;
  }

  .ant-select-disabled.ant-select-multiple .ant-select-selection-item {
    background: #e6eaf5;
    border: 1px solid #e6eaf5;
    color: #0e1936;
  }
`;

const StyledSelect = styled(Select)<IDropdownProps>`
  .ant-select-selection-item {
    padding: 4px 12px 4px 16px;
    height: auto;
  }
  ${(props) =>
    props.disabled &&
    `.ant-select-selector {
      padding: 0px;
    }

    .ant-select-selection-placeholder {
      color: #0e1936;
      left: 0px;
    }
  `}
`;

export { DropdownContainer, StyledSelect };

import styled from '@emotion/styled';

const StyledContainer = styled('div')`
  .ant-steps-item-icon {
    visibility: hidden;
  }

  .ant-steps-navigation .ant-steps-item::after {
    visibility: hidden;
  }

  .ant-steps-navigation {
    padding-top: 0px;
    height: 5px;
  }

  .ant-steps-item {
    background: #d1d5db;
    margin-top: 2px;
    margin-right: 4px;
  }

  .ant-steps-item-active {
    margin: 0 2px;
    background: none;
  }

  .ant-steps-navigation .ant-steps-item::before {
    background: #111827;
    transition-timing-function: linear;
    transition: width 0.3s;
    left: 0px;
  }
`;

const StyledStepsTitle = styled('div')`
  text-align: left;
`;

const StyledDescription = styled('div')`
  padding-bottom: 10px;

  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* gray/800 */

  color: #1f2937;
`;

export { StyledContainer, StyledStepsTitle, StyledDescription };

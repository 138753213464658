import { CheckCircleOutlined } from '@mui/icons-material';
import { Col, Form, message, Row } from 'antd';
import { useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ApiStatus } from '../../common/rootTypes';
import TextField from '../../components/Forms/TextField/TextField';
import { ChangeCustomerPasswordContext, ResetCustomerPasswordRequest } from './context/ChangeCustomerPasswordContext';
import {
  PasswordChangeContainer,
  StyledContainer,
  StyledError,
  StyledLoginForm,
  StyledLogo,
  StyledSimpleButton,
  StyledTitle,
} from './styles';

const gutterh = 16;
const gutterv = 24;
const largeColspan = 24;

const ChangeCustomerPassword = () => {
  const { changePassword, cpError, cpAPIStatus } = useContext(ChangeCustomerPasswordContext);

  const [form] = Form.useForm();

  const { otp, email } = useParams();

  const onSubmit = async () => {
    form
      .validateFields()
      .then(() => {
        const { password, confirmPassword } = form.getFieldsValue();
        if (password === confirmPassword) {
          const payload: ResetCustomerPasswordRequest = {
            password,
            token: otp!,
            email: email as string,
          };
          changePassword && changePassword(payload);
        } else {
          message.error('Please enter matching passwords');
        }
      })
      .catch((err) => {
        // handle errors
      });
  };

  return (
    <StyledContainer>
      <StyledLogo>FitClub</StyledLogo>
      {cpAPIStatus !== ApiStatus.succeeded ? (
        <StyledLoginForm form={form} name="cpForm" preserve={false}>
          <StyledTitle>Change Password</StyledTitle>
          <Row gutter={[gutterv, gutterh]}>
            <Col span={largeColspan}>
              <TextField placeholder="" name={'password'} label={'New Password'} type="password" />
            </Col>
          </Row>
          <Row gutter={[gutterv, gutterh]}>
            <Col span={largeColspan}>
              <TextField placeholder="" name={'confirmPassword'} label={'Confirm Password'} type="password" />
            </Col>
          </Row>
          {cpError && <StyledError>{cpError}</StyledError>}

          <Row gutter={[gutterv, gutterh]}>
            <Col span={largeColspan}>
              <StyledSimpleButton onClick={onSubmit} type="primary">
                Change Password
              </StyledSimpleButton>
            </Col>
          </Row>
        </StyledLoginForm>
      ) : (
        <StyledLoginForm>
          <PasswordChangeContainer>
            <CheckCircleOutlined sx={{ fontSize: 80, color: '#219653' }} />
            <StyledTitle>Password Changed</StyledTitle>
            <p>Your password has been changed. Use your new password to login next time.</p>
            <StyledSimpleButton type="primary">
              <Link to="/login">Login</Link>
            </StyledSimpleButton>
          </PasswordChangeContainer>
        </StyledLoginForm>
      )}
    </StyledContainer>
  );
};

export default ChangeCustomerPassword;

import React, { useCallback, useMemo } from 'react';
import { Form, Input, Tooltip } from 'antd';
import { Rule } from 'antd/lib/form';
import { ITextFieldProps } from './TextField.types';
import { TextFieldContainer } from './TextField.styles';
import FormLabel from '../FormLabel/FormLabel';

const TextField = ({
  name,
  required = false,
  requiredMark = false,
  requiredValMsg = 'Required',
  pattern,
  patternValMsg = 'Invalid',
  dependency,
  prefix,
  suffix,
  label,
  placeholder = 'Enter',
  disabled = false,
  maxLength = 255,
  type = 'text',
  defaultValue,
  tooltip,
}: ITextFieldProps) => {
  // create validation rules

  const createRules = useCallback(() => {
    const rules: Rule[] = [];
    let message: string | undefined = undefined;
    if (required) {
      message = requiredValMsg;
      rules.push({
        required,
        message,
        whitespace: true,
      });
    }

    if (pattern) {
      message = patternValMsg;
      rules.push({
        pattern: new RegExp(pattern),
        message,
      });
    }

    if (type == 'email') {
      rules.push({
        type,
        message: 'Invalid email',
      });
    }

    return rules;
  }, [pattern, patternValMsg, required, requiredValMsg, type]);

  const memoizedCreateRules = useMemo(() => createRules(), [createRules]);

  return (
    <TextFieldContainer id={name as string}>
      <>
        {label && <FormLabel label={label} required={required} requiredMark={requiredMark}></FormLabel>}
        <Tooltip trigger={['focus']} title={tooltip} placement="topLeft" overlayClassName="numeric-input">
          <Form.Item
            name={name}
            required={required}
            rules={memoizedCreateRules}
            initialValue={defaultValue}
            dependencies={dependency ? [dependency] : []}
          >
            <Input
              prefix={prefix}
              placeholder={placeholder}
              disabled={disabled}
              maxLength={maxLength}
              type={type}
              suffix={suffix}
            />
          </Form.Item>
        </Tooltip>
      </>
    </TextFieldContainer>
  );
};

export default TextField;

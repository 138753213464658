import styled from '@emotion/styled';
import SimpleButton from '../../../components/buttons/SimpleButton/SimpleButton';

const StyledContainer = styled('div')`
  padding: 40px 0;
`;

const StyledTitle = styled('div')`
  padding: 20px 0;
`;

const StyledSimpleButton = styled(SimpleButton)`
  margin: 20px 0;
`;

export { StyledContainer, StyledTitle, StyledSimpleButton };

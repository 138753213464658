import { Col, Form, Row } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SimpleButton from '../../../components/buttons/SimpleButton/SimpleButton';
import TextField from '../../../components/Forms/TextField/TextField';
import { ChangeOTPPasswordRequest, SignupContext, SignupRequest } from '../Signup/context/SignupProvider';
import {
  StyledButtonRow,
  StyledContainer,
  StyledContent,
  StyledForm,
  StyledFormSubtitle,
  StyledHeader,
  StyledLogo,
  StyledSection,
  StyledTitle,
} from './styles';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

const gutterh = 16;
const gutterv = 24;
const largeColspan = 12;

const ChangePasswordOTP = () => {
  const [form] = Form.useForm();
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

  const { changePasswordWithOTP } = useContext(SignupContext);
  const { otp, email } = useParams();

  const handleSignup = () => {
    form
      .validateFields()
      .then(() => {
        const { firstName, lastName, email, password, confirmPassword } = form.getFieldsValue();

        if (password !== confirmPassword) {
          form.setFields([
            {
              name: 'confirmPassword',
              errors: ['Please insert the same Password'],
            },
          ]);
          return;
        }

        const payload: ChangeOTPPasswordRequest = {
          firstName,
          lastName,
          email,
          newPassword: password,
          otp: otp || '',
        };
        changePasswordWithOTP && changePasswordWithOTP(payload);
      })
      .catch((err) => {
        // handle errors
      });
  };

  const alterVisibolity = () => setPasswordVisible(!passwordVisible);

  return (
    <>
      <StyledHeader>
        <StyledLogo className="logo">{'FitClub'}</StyledLogo>
      </StyledHeader>
      <StyledContainer>
        <StyledSection>
          <StyledForm form={form} name={'Test'} preserve={false}>
            <StyledTitle>Signup for FitClub</StyledTitle>

            <Row gutter={[gutterv, gutterh]}>
              <Col span={largeColspan}>
                <TextField name={'firstName'} label={'First Name'} required={true} />
              </Col>
              <Col span={largeColspan}>
                <TextField name={'lastName'} label={'Last Name'} required={true} />
              </Col>
            </Row>
            <Row gutter={[gutterv, gutterh]}>
              <Col span={largeColspan}>
                <TextField
                  name={'email'}
                  label={'Email Address'}
                  required={true}
                  disabled={true}
                  defaultValue={email}
                />
              </Col>
            </Row>
            <Row gutter={[gutterv, gutterh]}>
              <Col span={largeColspan}>
                <TextField
                  name={'password'}
                  label={'Password'}
                  type={passwordVisible ? 'text' : 'password'}
                  required={true}
                  suffix={
                    passwordVisible ? (
                      <EyeTwoTone onClick={alterVisibolity} />
                    ) : (
                      <EyeInvisibleOutlined onClick={alterVisibolity} />
                    )
                  }
                  pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
                  patternValMsg="Please enter a correct password"
                  tooltip="Please enter minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character"
                />
              </Col>
              <Col span={largeColspan}>
                <TextField
                  name={'confirmPassword'}
                  label={'Confirm Password'}
                  type={passwordVisible ? 'text' : 'password'}
                  required={true}
                  suffix={
                    passwordVisible ? (
                      <EyeTwoTone onClick={alterVisibolity} />
                    ) : (
                      <EyeInvisibleOutlined onClick={alterVisibolity} />
                    )
                  }
                  pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
                  patternValMsg="Please enter a correct password"
                  tooltip="Please enter minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character"
                />
              </Col>
            </Row>
            <StyledFormSubtitle>
              By creating an account, you agree to our{' '}
              <a target="_blank" rel="noopener noreferrer" href="https://www.fitclub.work/terms.pdf">
                &nbsp;Terms&nbsp;
              </a>
              and have read and acknowledge the
              <a target="_blank" rel="noopener noreferrer" href="https://www.fitclub.work/privacy.pdf">
                &nbsp;Global Privacy Statement
              </a>
              .
            </StyledFormSubtitle>
            <StyledButtonRow gutter={[gutterv, gutterh]}>
              <SimpleButton type="primary" onClick={handleSignup} className="primaryBtn">
                Sign Up
              </SimpleButton>
            </StyledButtonRow>
          </StyledForm>
        </StyledSection>
      </StyledContainer>
    </>
  );
};

export default ChangePasswordOTP;

import styled from '@emotion/styled';
import { Form, Row } from 'antd';
import SimpleButton from '../../components/buttons/SimpleButton/SimpleButton';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #eef2ff;
  height: 100vh;
`;

const StyledLoginForm = styled(Form)`
  background: #fff;
  padding: 40px;
  text-align: left;
`;

const StyledLogo = styled.div`
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
  /* identical to box height, or 133% */

  /* Label Color/Light/Primary */

  color: #000000;

  padding: 20px;
`;

const PasswordChangeContainer = styled.div`
  text-align: center;
`;

const StyledTitle = styled.div`
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  /* identical to box height, or 133% */

  /* gray/900 */

  color: #111827;

  padding: 10px 0;
`;

const StyledSubTitle = styled.div`
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* gray/900 */

  color: #111827;

  padding: 10px 0;
`;

const StyledSimpleButton = styled(SimpleButton)`
  width: 100%;
  background: #4338ca;
`;

const StyledError = styled.div`
  color: red;
  padding: 5px 0;
`;
export {
  StyledContainer,
  PasswordChangeContainer,
  StyledLoginForm,
  StyledLogo,
  StyledTitle,
  StyledSubTitle,
  StyledSimpleButton,
  StyledError,
};

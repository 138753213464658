import { SignupProvider } from './context/SignupProvider';
import Signup from './Signup';

const SignupWrapper = () => (
  <SignupProvider>
    <Signup></Signup>
  </SignupProvider>
);

export default SignupWrapper;

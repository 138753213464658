import { Col, DatePicker, Form, Row, Table } from 'antd';
import moment from 'moment';
import { useContext, useEffect, useMemo } from 'react';
import { DashboardContext } from '../Dashboard/context/DashboardProvider';
import StatCard from '../Dashboard/StatCard';
import {
  StyledSection,
  StyledColStats,
  StyledSubTitle,
  StyledMonthStatSection,
  StyledContainer,
  StyledDescription,
} from './styles';
import { months } from '../../common/rootTypes';
import { ActiveUserResponse, AverageUserStepsResponse, ReportContext } from './context/ReportProvider';
import Dropdown from '../../components/Forms/Dropdown/Dropdown';
import SimpleButton from '../../components/buttons/SimpleButton/SimpleButton';
import { dateFormat } from '../../common/utils';
import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';

const gutterh = 16;
const gutterv = 24;
const largeColspan = 12;
const xxlColSpan = 24;

const Reports = () => {
  const { RangePicker } = DatePicker;
  const [avgStepsForm] = Form.useForm();
  const [stepsRangeForm] = Form.useForm();

  const currentMonth = Number(moment().format('M'));
  const currentMonthvalue = useMemo(() => months.filter((month) => month.id === currentMonth), [currentMonth]);

  const { getStats, selectedStats, getMonthChallenges } = useContext(DashboardContext);
  const {
    getActiveUsers,
    selectedActiveUsers,
    getAvgSteps,
    selectedAvgSteps,
    getStepsForRange,
    selectedStepsForRange,
  } = useContext(ReportContext);

  useEffect(() => {
    getStats && getStats(currentMonth);
    // getMonthChallenges && getMonthChallenges(currentMonth);
    getActiveUsers && getActiveUsers(currentMonth);

    getStepsForRange && getStepsForRange(dateFormat(moment().subtract(12, 'week')), dateFormat(moment()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: '#',
      key: 'rank',
      render: (record: ActiveUserResponse) => {
        return selectedActiveUsers && selectedActiveUsers.indexOf(record) + 1;
      },
    },
    {
      title: 'First Name',
      key: 'firstName',
      render: (record: ActiveUserResponse) => <>{record.firstName}</>,
    },
    {
      title: 'Last Name',
      key: 'lastName',
      render: (record: ActiveUserResponse) => <>{record.lastName}</>,
    },
  ];

  const avgStepsColumns = [
    {
      title: '#',
      key: 'rank',
      render: (record: AverageUserStepsResponse) => {
        return selectedAvgSteps && selectedAvgSteps.indexOf(record) + 1;
      },
    },
    {
      title: 'First Name',
      key: 'firstName',
      render: (record: AverageUserStepsResponse) => <>{record.firstName}</>,
    },
    {
      title: 'Last Name',
      key: 'lastName',
      render: (record: AverageUserStepsResponse) => <>{record.lastName}</>,
    },
    {
      title: 'Average Steps',
      key: 'avgSteps',
      render: (record: AverageUserStepsResponse) => <>{Math.round(record.avgSteps)}</>,
    },
  ];

  const loadAvgSteps = () => {
    avgStepsForm.validateFields().then(() => {
      const daterange = avgStepsForm.getFieldValue('avgStepsDateRange');
      getAvgSteps && getAvgSteps(dateFormat(daterange[0]), dateFormat(daterange[1]));
    });
  };

  const formattedSelectedStepsForRange = selectedStepsForRange?.map((rec) => ({
    steps: Number(rec.steps),
    date: moment(rec.date),
  }));

  return (
    <StyledContainer>
      <StyledMonthStatSection>
        <div className="date-container">
          <div className="contentTitle">In</div>
          <div className="dropdown-month">
            <Dropdown
              name="month"
              items={months}
              defaultValue={currentMonthvalue[0].text}
              onChange={(e) => {
                getStats && getStats(Number(e));
                getActiveUsers && getActiveUsers(Number(e));
              }}
            />
          </div>
        </div>
        <StyledSection>
          <StyledSubTitle className="contentTitle">{'Together we have achieved,'}</StyledSubTitle>
          <Row>
            <StyledColStats span={6}>
              <StatCard
                title="Total Distance"
                value={selectedStats?.totalDistance || 0}
                contributors={selectedStats?.distanceContributors || []}
              />
            </StyledColStats>
            <StyledColStats span={6}>
              <StatCard
                title="Total Steps"
                value={selectedStats?.totalSteps || 0}
                contributors={selectedStats?.stepContributors || []}
              />
            </StyledColStats>
            <StyledColStats span={6}>
              <StatCard
                title="Total Exercise Minutes"
                value={selectedStats?.totalExerciseMinutes || 0}
                contributors={selectedStats?.minuteContributors || []}
              />
            </StyledColStats>
            <StyledColStats span={6}>
              <StatCard
                title="Total Active Energy Burnt"
                value={selectedStats?.totalActiveEnergyBurnt || 0}
                contributors={selectedStats?.caloryContributors || []}
              />
            </StyledColStats>
          </Row>
        </StyledSection>
      </StyledMonthStatSection>

      <StyledSection>
        <StyledSubTitle className="contentTitle">{'Monthly Active Users'}</StyledSubTitle>
        {selectedActiveUsers && selectedActiveUsers.length > 0 ? (
          <Table dataSource={selectedActiveUsers} columns={columns} />
        ) : (
          'No active users for selected month'
        )}
      </StyledSection>

      <StyledSection>
        <StyledSubTitle className="contentTitle">{'Average User Steps'}</StyledSubTitle>
        <Form form={avgStepsForm}>
          <Row gutter={[gutterh, gutterv]}>
            <Col>
              <Form.Item
                name={'avgStepsDateRange'}
                rules={[
                  {
                    required: true,
                    message: 'Required',
                  },
                ]}
                required={true}
              >
                <RangePicker />
              </Form.Item>
            </Col>
            <Col>
              <SimpleButton onClick={loadAvgSteps} size="middle">
                Go
              </SimpleButton>
            </Col>
          </Row>
        </Form>
        {selectedAvgSteps && selectedAvgSteps.length > 0 ? (
          <Table dataSource={selectedAvgSteps} columns={avgStepsColumns} />
        ) : (
          'No records for the selected date range'
        )}
      </StyledSection>

      <StyledSection>
        <StyledSubTitle className="contentTitle">{'Collective steps'}</StyledSubTitle>
        <StyledDescription>
          This is a collective effort of all the participants in your workplace during the last 8 weeks. Here shows the
          collective progression of steps.
        </StyledDescription>
        {formattedSelectedStepsForRange && formattedSelectedStepsForRange.length > 0 ? (
          <LineChart
            width={730}
            height={220}
            data={formattedSelectedStepsForRange}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="steps" stroke="#4338ca" />
          </LineChart>
        ) : (
          'No records for the selected date range'
        )}
      </StyledSection>
    </StyledContainer>
  );
};

export default Reports;

import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SignupContext } from '../Signup/context/SignupProvider';

const VerifyEmail = () => {
  const { verifyEmail } = useContext(SignupContext);
  const { otp, email } = useParams();

  useEffect(() => {
    otp && email && verifyEmail && verifyEmail(otp, email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default VerifyEmail;

import { SignupProvider } from '../Signup/context/SignupProvider';
import VerifyEmail from './VerifyEmail';

const VerifyEmailWrapper = () => (
  <SignupProvider>
    <VerifyEmail></VerifyEmail>
  </SignupProvider>
);

export default VerifyEmailWrapper;

import styled from '@emotion/styled';
import { List } from 'antd';

const StyledError = styled('div')`
  padding: 5px 0;
  text-align: left;
  color: red;
`;

const StyledTitle = styled('div')`
  text-align: left;

  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  padding: 10px 0;
`;

const StyledDescription = styled('div')`
  text-align: left;

  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  padding: 10px 0;
`;

const StyledListContainer = styled('div')`
  text-align: left;
`;

const StyledInput = styled('input')`
  display: none;
`;

const StyledTagContainer = styled('div')`
  .rti--input {
    background: #f5f5f5;
  }
`;

const StyledContainer = styled('div')`
  // .ant-spin-container {
  //   height: 40vh !important;
  //   position: relative;
  // }
`;

export {
  StyledError,
  StyledDescription,
  StyledTitle,
  StyledListContainer,
  StyledInput,
  StyledTagContainer,
  StyledContainer,
};

import ChangeCustomerPassword from './ChangeCustomerPassword';
import { ChangeCustomerPasswordProvider } from './context/ChangeCustomerPasswordContext';

const ResetCustomerPasswordWrapper = () => (
  <ChangeCustomerPasswordProvider>
    <ChangeCustomerPassword></ChangeCustomerPassword>
  </ChangeCustomerPasswordProvider>
);

export default ResetCustomerPasswordWrapper;

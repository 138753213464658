/* eslint-disable prefer-const */
import React, { ReactElement, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { registerCustomerApi, requestEnterpriseProductApi, subscribeCustomerApi } from '../../../api/axios-api';
import { ApiStatus } from '../../../common/rootTypes';
import { GlobalContext } from '../../../context/GlobalProvider';

export type RegisterCustomerRequest = {
  admins: string[];
  members: string[];
};

export type SubscribeCustomerRequest = {
  productId: string;
};

export type Subscription = {
  clientSecret: string;
  productId: string;
};

type OnboardContextProp = {
  registerCustomer: ((registerCustomerRequest: RegisterCustomerRequest) => Promise<void>) | null;
  subscribeCustomer: ((subscribeCustomerRequest: SubscribeCustomerRequest) => Promise<void>) | null;
  registerCustomerStatus: ApiStatus;
  subscription: Subscription | null;
  requestEnterprise: (() => Promise<void>) | null;
};

export const initialState: OnboardContextProp = {
  registerCustomer: null,
  subscribeCustomer: null,
  registerCustomerStatus: ApiStatus.idle,
  subscription: null,
  requestEnterprise: null,
};

export const OnboardContext = React.createContext<OnboardContextProp>(initialState);

export const OnboardProvider = (props: { children: ReactElement | ReactElement[] }) => {
  const { setSpinning } = useContext(GlobalContext);
  const navigate = useNavigate();

  const { children } = props;
  const [apiStatus, setApiStatus] = useState<ApiStatus>(ApiStatus.idle);
  const [subscription, setSubscription] = useState<Subscription | null>(null);

  const registerCustomer = async (registerCustomerRequest: RegisterCustomerRequest) => {
    setSpinning && setSpinning(true);

    setApiStatus(ApiStatus.pending);

    try {
      let response = await registerCustomerApi(registerCustomerRequest);
      if (response.status == 200) {
        setApiStatus(ApiStatus.succeeded);
        navigate('/');
      } else {
        //set error code
        setApiStatus(ApiStatus.failed);
      }
      setSpinning && setSpinning(false);
    } catch (error) {
      setSpinning && setSpinning(false);
    }
  };

  const subscribeCustomer = async (subscribeCustomerRequest: SubscribeCustomerRequest) => {
    setSpinning && setSpinning(true);

    setApiStatus(ApiStatus.pending);

    try {
      let response = await subscribeCustomerApi(subscribeCustomerRequest);
      if (response.status == 200) {
        setSubscription(response.data.body);
        setApiStatus(ApiStatus.succeeded);
      } else {
        //set error code
        setApiStatus(ApiStatus.failed);
      }
      setSpinning && setSpinning(false);
    } catch (error) {
      setSpinning && setSpinning(false);
    }
  };

  const requestEnterprise = async () => {
    setSpinning && setSpinning(true);

    setApiStatus(ApiStatus.pending);

    try {
      let response = await requestEnterpriseProductApi();
      if (response.status == 200) {
        setApiStatus(ApiStatus.succeeded);
      } else {
        //set error code
        setApiStatus(ApiStatus.failed);
      }
      setSpinning && setSpinning(false);
    } catch (error) {
      setSpinning && setSpinning(false);
    }
  };

  const store: OnboardContextProp = {
    registerCustomer,
    subscribeCustomer,
    registerCustomerStatus: apiStatus,
    subscription: subscription,
    requestEnterprise: requestEnterprise,
  };

  return <OnboardContext.Provider value={store}>{children}</OnboardContext.Provider>;
};
